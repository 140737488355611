
export const getCombinedAgentsData = ({ getIndividualTransactionData = {}, agentData = [], agentList = [], agentType="" }) => {
  const transactionData = getIndividualTransactionData?.newTransactionData 
  if(!transactionData) return []
  const agentMap = agentData.reduce((acc, agent) => {
    if (agent?.profileImg?.original) {
      acc[String(agent._id)] = agent.profileImg.original;
    }
    return acc;
  }, {});

  const updatedAgents =
    transactionData[agentType]?.map((el) => {
      let profileImg;
      if (typeof el.personId === "string" && el.personId.length === 24) {
        profileImg = agentMap[el.personId];
      }
      return {
        ...el,
        ...(profileImg ? { profileImg } : {}),
      };
    }) || [];
  agentList.push(...updatedAgents);
  
};

export const getCombinedTitleCompanyData = ({ getIndividualTransactionData, source }) => {
  const titleRepsData = [];
  const affiliatesData = [];

  const titleDetails = getIndividualTransactionData?.titleCompanyDetails;

  if (titleDetails && ["SELLING_RTD", "CONTRACT_NAV_BUYER_AGENT", "CONTRACT_NAV_SELLER_AGENT"].includes(source)) {
    // Adding Sales Rep and Title Closer
    if (titleDetails.salesRepName?.trim() && titleDetails.salesRepEmail?.trim()) {
      titleRepsData.push({
        fullName: titleDetails.salesRepName?.trim() || "",
        email: titleDetails.salesRepEmail?.trim() || "",
        _id: `${titleDetails.salesRepName?.toLowerCase().replace(/\s+/g, "") || ""}-${titleDetails.salesRepEmail?.toLowerCase() || ""}`,
      });
    }
     // Adding Title Closer
    if (titleDetails.titleCloserName?.trim() && titleDetails.titleCloserEmail?.trim()) {
      titleRepsData.push({
        fullName: titleDetails.titleCloserName?.trim() || "",
        email: titleDetails.titleCloserEmail?.trim() || "",
        _id: `${titleDetails.titleCloserName?.toLowerCase().replace(/\s+/g, "") || ""}-${titleDetails.titleCloserEmail?.toLowerCase() || ""}`,
      });
    }

    // Adding Processors
    titleDetails.processors?.forEach((processor) => {
      if (processor?.name?.trim() && processor?.email?.trim()) {
        titleRepsData.push({
          fullName: processor.name.trim(),
          email: processor.email.trim(),
          _id: processor._id || "",
        });
      }
    });

    // Adding Affiliates
    titleDetails.affiliateDetails?.affiliates?.forEach((affiliate) => {
      if (affiliate?.name?.trim() && affiliate?.email?.trim()) {
        affiliatesData.push({
          fullName: affiliate.name.trim(),
          email: affiliate.email.trim(),
          _id: affiliate._id || "",
        });
      }
    });
  }

  return { titleRepsData, affiliatesData };
};
