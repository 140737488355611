export const calendarConstants = {
  CALENDAR_DATA_SUCCESS: "CALENDAR_DATA_SUCCESS",
  CALENDAR_DATA_FAILURE: "CALENDAR_DATA_FAILURE",

  CALENDAR_GETDATA_SUCCESS: "CALENDAR_GETDATA_SUCCESS",
  CALENDAR_GETDATA_FAILURE: "CALENDAR_GETDATA_FAILURE",

  GET_SCHEDULE_LIST_SUCCESS: "GET_SCHEDULE_LIST_SUCCESS",
  GET_SCHEDULE_LIST_FAILURE: "GET_SCHEDULE_LIST_FAILURE",

  GET_REMINDERS_LIST_SUCCESS: "GET_REMINDERS_LIST_SUCCESS",
  GET_REMINDERS_LIST_FAILURE: "GET_REMINDERS_LIST_FAILURE",

  RESET_CALENDAR: "RESET_CALENDAR",

  GET_CALENDAR_LOADING: "GET_CALENDAR_LOADING",

  SHARE_CALENDAR_LOADING: "SHARE_CALENDAR_LOADING",
  SHARE_CALENDAR_SUCCESS: "SHARE_CALENDAR_SUCCESS",
  SELECT_AGENT: "SELECT_AGENT"
};
