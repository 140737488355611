import { Badge, Button, Modal } from "antd";
import moment from "moment";
import React, { useState } from "react";
import styles from "../styles/EventDetailModal.module.css";
import TextArea from "antd/lib/input/TextArea";
import { useDispatch } from "react-redux";
import { calendarHandle } from "../state/actions";

function EventDetailModal({ setModalStates, modalStates, handleCurrentMonth, calendarDataEncrypt, source }) {
  const isContractSchedule = modalStates?.event?.type === "CONTRACT_SCHEDULE";
  const [saveNotes,setSaveNotes]=useState(modalStates?.event?.description);
  const dispatch = useDispatch();

  const handleChangeNotes = (e) => {
    setSaveNotes(e.target.value); // Update the current notes
  };
  const handleSaveNotes = (e) => {
    dispatch(calendarHandle.editCustomTask({payloadData: {description: saveNotes, completedDateMili : moment(modalStates?.event?.completedDateMili).valueOf(), updateFrom :"CALENDAR_MONTH" }, taskId: modalStates?.event?._id, handleCurrentMonth, urlData: calendarDataEncrypt}))
  };

  const handleCancel = () => setModalStates((prev) => ({ ...prev, openDetailModal: false }))

  const toggleTaskCompletion = (checked) => {
    setModalStates((prev) => ({
      ...prev,
      event: {
        ...prev.event,
        completedDateMili: checked ? Date.now() : null,
      },
    }));
  };

  return (
    <>
      <Modal
        title={
          <Badge.Ribbon
            text="Contract Schedule"
            color="#178DFA"
            style={{ display: "none" }}
            placement="start"
          >
            <div style={{ marginTop: isContractSchedule && false ? 5 : undefined, paddingTop: isContractSchedule && false ? undefined : 3 }}>
              {modalStates?.event?.address?.fullAddress ? <div className={source === "HOME_CALENDAR" ? styles.modalFullAddressHyperLink : styles.fullAddress}  onClick={modalStates?.event?.handleTransactionRedirect}>{modalStates?.event?.address?.fullAddress}</div> : modalStates?.event?.fullAddress ? <div className={source === "HOME_CALENDAR" ? styles.modalFullAddressHyperLink : styles.fullAddress}  onClick={modalStates?.event?.handleTransactionRedirect}>{modalStates?.event?.fullAddress}</div> : null}

              {modalStates?.event?.eventName ? <div className={styles.eventName}>{modalStates?.event?.eventName}</div> : modalStates?.event?.title ? <div className={styles.eventName}>{modalStates?.event?.title}</div> : null}
            </div>
          </Badge.Ribbon>
        }
        visible={true}
        closable
        onCancel={handleCancel}
        footer={null}
        className="EventDetailModal"
        style={{marginTop: "-70px"}}
        bodyStyle={{ paddingTop: "5px" }}
      >
        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "-5px" }}>
          {["REMINDER", "SCHEDULE", "CONTRACT_SCHEDULE"].includes(modalStates?.event?.type) && modalStates?.event?.start ? (
            <div className={styles.modalContents}>
              Start time: <span style={{ color: "black" }}>{moment(modalStates.event?.start).format("MM/DD/YYYY, h:mm A")}</span>
            </div>
          ) : null}
          {["SCHEDULE", "CONTRACT_SCHEDULE"].includes(modalStates?.event?.type) && modalStates?.event?.end ? (
            <div className={styles.modalContents}>
              End time: <span style={{ color: "black" }}>{moment(modalStates.event?.end).format("MM/DD/YYYY, h:mm A")}</span>
            </div>
          ) : null}
        </div>
        <div className={styles.eventDates}>
          {modalStates?.event?.dateOrDeadline ? (
            <div className={styles.modalContents}>
              Due Date: <span>{moment(modalStates.event?.dateOrDeadline).format("MM/DD/YYYY")}</span>
            </div>
          ) : null}
          {modalStates?.event?.completedDateMili ? (
            <div className={styles.modalContents}>
              Completed Date: <span style={{ color: "green" }}>{moment(modalStates.event?.completedDateMili).format("MM/DD/YYYY")}</span>
            </div>
          ) : null}
        </div>
        <div className={styles.modalContents } style={{ fontWeight: "bold" ,color:"gray"}}>Notes:</div>
        <TextArea
        style={{ background: "lightgoldenrodyellow" }}
        value={saveNotes}
        onChange={handleChangeNotes}
        rows={4}
        placeholder="Write your notes here..."
        disabled={['CLIENT_SCREEN', 'ReadOnlyClientScreen'].includes(calendarDataEncrypt?.screen) || ['SHARE_CALENDAR', 'READ_ONLY_DOCUMENT'].includes(calendarDataEncrypt?.builtForSection)}
      />
       <div>
          <label style={{ cursor: "pointer", userSelect: "none" }}>
            <input
              type="checkbox"
              checked={modalStates?.event?.completedDateMili != null}
              onChange={(e) => toggleTaskCompletion(e.target.checked)}
              style={{ marginRight: "8px" }}
            />
            {modalStates?.event?.completedDateMili ? "Mark as InComplete" : "Mark as Complete"}
          </label>
        </div>
      {!(['CLIENT_SCREEN', 'ReadOnlyClientScreen'].includes(calendarDataEncrypt?.screen) || ['SHARE_CALENDAR', 'READ_ONLY_DOCUMENT'].includes(calendarDataEncrypt?.builtForSection)) &&
        <div style={{ display: "flex", marginTop: "10px", width: "100%", justifyContent: "center" }}>
          {" "}
          <Button type="primary" size="small" onClick={handleSaveNotes}>
            Save
          </Button>
          <Button size="small" style={{ marginLeft: "10px" }} onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      }

        {/* </div> */}
      </Modal>
    </>
  );
}

export default EventDetailModal;
