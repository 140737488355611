import moment from "moment";
import { buildPropertyStreetAddress } from "../../Common/utils/universalFuntions";
import getFullNamesFromArray from "../../CreateOffer/features/helpers/getFullNamesFromArray";
import styles from "../styles/CustomCalendar.module.css";
import React from "react";

const TaskInfo = ({ event, handleTransactionRedirect, source }) => {
  const formatSigners = (signers) =>
    signers?.map((ele, ind, arr) => (
      <span key={ind}>
        {ele?.fullName}
        {ind === arr.length - 1 ? "" : ", "}
      </span>
    ));

  const customerNames = event?.clientIds?.map((el, index, array) => (
    <React.Fragment key={index}>
      {!el?.isCorp ? (
        <span>
          {el?.fullName}
          {index === array.length - 1 ? "" : ", "}
        </span>
      ) : (
        <span>
          {el?.fullName}
          {el?.signers?.length > 0 && (
            <span>
              {" ("}
              {formatSigners(el.signers)}
              {")"}
            </span>
          )}
          {index === array.length - 1 ? "" : ", "}
        </span>
      )}
    </React.Fragment>
  ));

  return (
    <div style={{ width: "100%", display: "flex", gap: "5px", flexDirection: "column", padding: "5px 0px" }}>
      {source === "HOME_CALENDAR" ? (
        <div className={styles.cardFullAddressHyperLink} onClick={handleTransactionRedirect}>
          {buildPropertyStreetAddress(event.address)}
        </div>
      ) : (
        <div className={styles.cardFullAddress}>{buildPropertyStreetAddress(event.address)}</div>
      )}
      <div style={{ display: "flex" }}>
        <span className={styles.hoverTaskLabel}>Name:</span>
        <span>{customerNames || ""}</span>
      </div>
      <div style={{ display: "flex" }}>
        <span className={styles.hoverTaskLabel}>Closing:</span>
        <span>{!isNaN(Date.parse(event?.ClosingDate)) ? moment(event?.ClosingDate).format("MM/DD/YYYY") : "N/A"}</span>
      </div>
      <div style={{ display: "flex" }}>
        <span className={styles.hoverTaskLabel}>Agent:</span>
        <span>{getFullNamesFromArray(event.agentIds)}</span>
      </div>
      <div style={{ display: "flex" }}>
        <span className={styles.hoverTaskLabel}>Task:</span>
        <span>{event?.title || ""}</span>
      </div>
      <div style={{ display: "flex" }}>
        <span className={styles.hoverTaskLabel}>Notes:</span>
        <span>{event?.description || ""}</span>
      </div>
    </div>
  );
};

export { TaskInfo };
