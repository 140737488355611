import buildSignatureBlocks from "./helpers/buildSignatureBlocks";
import getFullNamesFromArray from "./helpers/getFullNamesFromArray";
import removeSection from "./helpers/removeSection";
import setDayOfWeek from "./helpers/setDayOfWeek";
import { handleDifferentKeyOfClient, handleSignatureCompletion, makeFieldsHiddenForTitleCompany, removeAddButtonForReadOnly, renderAgentSignatureBox, showCompanyDetails, updateSection3Fields } from "./helperFunctions";
import imagePlaceholder from "../../Assets/Icons/imagePlaceholder.webp";
import { addDynamicSellerBuyer } from "./helpers/dynamicSellerBuyerTitleOrder";
import { formatDate, formatPhoneNumber, getCombinedClients } from "../../Common/utils/universalFuntions";
import { transactionReportDynamicFields } from "./helpers/transactionReportDynamicFields";
import { convertTime, createOfferContractTypes } from "../../Common/commondata/commonData";
import { checkDynamicFieldsOfNetSheet } from "./helpers/netSheetDynamicComponents";
import { calculateFinalNetSheetPrice } from "./helpers/netsheetAmtCalculater";

const SetInitialValues = ({ contractData, currentUrlId, rootDocData, getTransactionReportData, transactionEmail, CurrentPdfImportData, sharedDocs, source }) => {  
  // filling all the values
  let td = {};
  if (rootDocData?.contractType === "TR") {
    td = getTransactionReportData?.transactionData || {};

    if (getTransactionReportData) {
      // RE-Sure TRANSACTION DOCUMENTS
      transactionReportDynamicFields(getTransactionReportData?.documentsData, "DOCUMENTS");

      // Signature Details
      transactionReportDynamicFields(getTransactionReportData?.documentsData, "SIGNATURE_DETAILS");

      const clientData = getCombinedClients({allContractDocuments: getTransactionReportData?.documentsData});

      // Buyers details
      addDynamicSellerBuyer("Buyers", clientData?.combinedBuyerIds, rootDocData.contractType);

      // Sellers details
      addDynamicSellerBuyer("Sellers", clientData?.combinedSellerIds, rootDocData.contractType);
    }
    if (transactionEmail) {
      // Email Communications
      transactionReportDynamicFields(transactionEmail, "EMAIL_COMMUNICATIONS");
    }
    if (sharedDocs) {
      // shared documents table
      transactionReportDynamicFields(sharedDocs, "SHARED_DOCS");
    }
  } else if (source === "OFFER_IMPORT" && CurrentPdfImportData) {
    td = CurrentPdfImportData;
  } else {
    td = JSON.parse(localStorage.getItem(currentUrlId));
  }

  let fillValueFor = null; // The Person who selected title company

  if (td && Object.keys(td)?.length > 0) {
    if (rootDocData?.contractType === "TO") {
      // adding placeholder image for title office in TO form if office not selected
      if (!td["TitleCompanyLogo"]) {
        const titleOfficeImageElement = Array.from(document.getElementsByName("TitleCompanyLogo"))?.[0];
        if (titleOfficeImageElement) titleOfficeImageElement.src = imagePlaceholder;
      }

      // If there is a single sellerAgent and buyerAgent (and both are the same) in the transaction:
      if (td["SellerAgents"]?.length === 1 && td["BuyerAgents"]?.length === 1 && td["SellerAgents"]?.[0]?.personId === td["BuyerAgents"]?.[0]?.personId) {
        fillValueFor = rootDocData?.titleCompanyDetails?.selectedBy || fillValueFor;
      }

      if ((fillValueFor === null && (td["SellerAgents"]?.length > 1 || td["BuyerAgents"]?.length > 1)) || td["SellerAgents"]?.[0]?.personId !== td?.["BuyerAgents"]?.[0]?.personId) {
        const doubleSidedTransactionCheckbox = document.getElementsByName("DoubleSidedTransaction")?.[0];
        if (doubleSidedTransactionCheckbox) {
          doubleSidedTransactionCheckbox.disabled = true; // In case of multiple agents, disable it.
        }
      }
        // setting populated data for TO like TR
      const clientData = getCombinedClients({allContractDocuments: getTransactionReportData?.documentsData});
      if (clientData?.combinedBuyerIds?.length) td['Buyers'] = clientData?.combinedBuyerIds;
      if (clientData?.combinedSellerIds?.length) td['Sellers'] = clientData?.combinedSellerIds;
    }
    if (rootDocData?.contractType === "NET_SHEET") {
      checkDynamicFieldsOfNetSheet(td);
      let dataToSet = calculateFinalNetSheetPrice({td});
      if(dataToSet && Object.keys(dataToSet).length > 0) {
        td = {...td, ...dataToSet};
      }
      
      // Remove visibility of some fields in NetSheet for Title Company
      if (["TITLE_COMPANY"].includes(rootDocData?.openFor)) {
        makeFieldsHiddenForTitleCompany();
      }
      // Remove buttons for read only document and for buyer agent
      if (!(['SELLER_AGENT', 'TITLE_COMPANY'].includes(rootDocData?.openFor) && rootDocData?.builtForSection === "DOCUMENT") || rootDocData?.builtForSection === "READ_ONLY_DOCUMENT") {
        removeAddButtonForReadOnly();
      }
    }

    for (const key in td) {
      const savedValue = td[key];
      if (key === "Buyers" && rootDocData?.builtForSection !== "TEMPLATE") {
        const buyerStr = getFullNamesFromArray(savedValue);
        const elements = document.querySelectorAll("[data-buyers-list]");
        if (elements.length) {
          // Ensure elements exist
          elements.forEach((element) => {
            if (source === "OFFER_IMPORT") {
              element.innerHTML = buyerStr;
            } else {
              element.value = buyerStr;
            }
            element.parentNode.dataset.replicatedValue = buyerStr;
          });
        }

        let i = 0;
        while (true) {
          let signatureBlockName = i ? `BUYER_${i}` : `BUYER`;
          let signatureElement = document.querySelectorAll(`[data-signature-block="${signatureBlockName}"]`);
          if (!signatureElement?.length) {
            break;
          }

          //for withdraw enabling
          let signEnabled = {};
          let withdrawSignAllowed = td?.["LetBuyersSignWithDrawBlock"];
          let buyerWithDrawPresent = contractData?.signatureSequence?.buyerWithdraw
          if((i > 0) && buyerWithDrawPresent){
            if(!withdrawSignAllowed){
              signEnabled = {
                checkForDisable: {disabled: true}
              }
            }
          }

          const currentBuyerClient = handleDifferentKeyOfClient({ clientArray: savedValue, i });
          const sigBlock = buildSignatureBlocks(currentBuyerClient, "Buyers", { ...rootDocData, ...signEnabled, personKey: `${rootDocData?.builtForId}${i ? `_${i}` : ""}`, key: `${rootDocData?.key}${i ? `_${i}` : ""}` }, "BUYER", contractData);

          signatureElement.forEach((element) => {
            element.innerHTML = sigBlock;
          });

          let signatureBlockName2 = i ? `BUYER_WITHDRAW_${i}` : `BUYER_WITHDRAW`;
          let signatureElement2 = document.querySelectorAll(`[data-signature-block="${signatureBlockName2}"]`);
          const sigBlockWithDraw = buildSignatureBlocks(currentBuyerClient, "Buyers", { ...rootDocData, personKey: `${rootDocData?.builtForId}${i ? `_${i}` : ""}`, key: `${rootDocData?.key}${i ? `_${i}` : ""}` }, "BUYER_WITHDRAW", contractData);
          signatureElement2.forEach((element) => {
            element.innerHTML = sigBlockWithDraw;
          });
          i++;
        }

        if ((rootDocData?.contractType === "TO") && Array.isArray(savedValue) && savedValue.length) {
          addDynamicSellerBuyer("Buyers", savedValue, rootDocData.contractType);
        }
      } else if (key === "Sellers" && rootDocData?.builtForSection !== "TEMPLATE") {
        const sellerStr = getFullNamesFromArray(savedValue);
        const elements = document.querySelectorAll("[data-sellers-list]");
        if (elements.length) {
          // Ensure elements exist
          elements.forEach((element) => {
            element.value = sellerStr;
            element.parentNode.dataset.replicatedValue = sellerStr;
          });
        }
        let i = 0;
        while (true) {
          let signatureBlockName = i ? `SELLER_${i}` : `SELLER`;
          let signatureElement = document.querySelectorAll(`[data-signature-block="${signatureBlockName}"]`);
          if (!signatureElement?.length) {
            break;
          }
          const currentBuyerClient = handleDifferentKeyOfClient({ clientArray: savedValue, i });
          const sigBlock = buildSignatureBlocks(currentBuyerClient, "Sellers", { ...rootDocData, personKey: `${rootDocData?.builtForId}${i ? `_${i}` : ""}`, key: `${rootDocData?.key}${i ? `_${i}` : ""}` }, "SELLER", contractData);
          signatureElement.forEach((element) => {
            element.innerHTML = sigBlock;
          });
          i++;
        }
        if ((rootDocData?.contractType === "TO") && Array.isArray(savedValue) && savedValue.length) {
          addDynamicSellerBuyer("Sellers", savedValue, rootDocData.contractType);
        }
      } else if (key === "BuyerAgents" && rootDocData?.builtForSection !== "TEMPLATE") {
        updateSection3Fields({ localData: td, agents: savedValue, agentType: key });
        renderAgentSignatureBox({ rootDocData, openFor: "BUYER_AGENT", agentType: "CO_BUYER_BROKER", savedValue, contractData });
        if ((rootDocData?.contractType === "TO" || rootDocData?.contractType === "TR") && Array.isArray(savedValue) && savedValue.length) {
          if (rootDocData?.contractType === "TO" && fillValueFor === "SELLER_AGENT" && !td["DoubleSidedTransaction"]) {
            let obj = savedValue[0];
            Object.keys(obj).forEach((key) => {
              if (key !== "personId") {
                obj[key] = "";
              }
            });
          }
          addDynamicSellerBuyer(key, savedValue, rootDocData?.contractType);
        }
      } else if (key === "SellerAgents" && rootDocData?.builtForSection !== "TEMPLATE") {
        if (!["ERTS"].includes(rootDocData?.contractType)) {
          updateSection3Fields({ localData: td, agents: savedValue, agentType: key });
        }
        renderAgentSignatureBox({ rootDocData, openFor: "SELLER_AGENT", agentType: "CO_SELLER_BROKER", savedValue, contractData });
        if ((rootDocData?.contractType === "TO" || rootDocData?.contractType === "TR") && Array.isArray(savedValue) && savedValue.length) {
          if (rootDocData?.contractType === "TO" && fillValueFor === "BUYER_AGENT" && !td["DoubleSidedTransaction"]) {
            let obj = savedValue[0];
            Object.keys(obj).forEach((key) => {
              if (key !== "personId") {
                obj[key] = "";
              }
            });
          }
          addDynamicSellerBuyer(key, savedValue, rootDocData?.contractType);
        }
      } else if (key === "TitleCompanyLogo") {
        const titleOfficeImageElement = document.getElementsByName("TitleCompanyLogo")[0];
        if (savedValue && titleOfficeImageElement) {
          titleOfficeImageElement.src = savedValue;
        }
      } else {
        if (Array.isArray(savedValue)) {
          savedValue.forEach((value) => {
            // console.log("checking if", savedValue, " is selected");
            document.getElementsByName(key).forEach((element, i) => {
              if (element.value === value) {
                element.checked = true;
              }
              if (element.type === "checkbox") {
                if (element.value === savedValue) {
                  element.checked = true;
                }
                if (element.hasAttribute("data-remove-target") && i === 0) {
                  // console.log("section deleted. removing section for", element.name);
                  removeSection(element);
                }
                // if deleted or No Change checkbox is checked already
                if (["No Change", "Deleted"].includes(element.value) && element.checked) {
                  let table31Inputs = document.getElementsByName(element.name);
                  if (table31Inputs && table31Inputs[0]) {
                    table31Inputs[0].value = "";
                  }
                }
              }
            });
          });
        } else if (typeof savedValue === "object") {
          const elements = document.getElementsByName(key);
          if (elements) {
            elements.forEach((element, i) => {
              // console.log("element", element);
              if (element.type !== "checkbox") {
                if (savedValue && savedValue.type === "date") {
                  element.value = formatDate(savedValue.value);
                  if (element.hasAttribute("data-setdayofweek") && i === 0) {
                    setDayOfWeek({
                      name: element.name,
                      value: element.value,
                    });
                  }
                } else if (savedValue && savedValue?.type === "mec") {
                  element.value = `${savedValue.value} days after MEC`;
                  if (element.hasAttribute("data-setdayofweek") && i === 0) {
                    setDayOfWeek({
                      name: element.name,
                      value: "",
                    });
                  }
                } else if (savedValue && savedValue.type === "text") {
                  element.value = savedValue.value;
                  if (element.hasAttribute("data-setdayofweek") && i === 0) {
                    setDayOfWeek({
                      name: element.name,
                      value: "",
                    });
                  }
                } else if (savedValue && savedValue.type === "time") {
                  element.value = convertTime(savedValue.value);
                }
                if (element.parentNode.classList.contains("grow-with-input")) {
                  element.parentNode.dataset.replicatedValue = savedValue;
                }
              } else {
                element.checked = false;
              }
            });
          }
        } else {
          // it is a single value
          const elements = document.getElementsByName(key);
          if (elements) {
            elements.forEach((element, i) => {
              if (element.type === "checkbox") {
                if (element.value === savedValue) {
                  element.checked = true;
                } else {
                  element.checked = false;
                }
                if (element.hasAttribute("data-remove-target") && i === 0) {
                  // console.log("section deleted. removing section for", element.name);
                  removeSection(element);
                }
              } else {
                if (element.classList.contains("relativeDateInput") && !isNaN(Date.parse(savedValue)) && element.type !== "date") {
                  element.value = formatDate(savedValue);
                } else if (element.classList.contains("relativeTimeInput")) {
                  element.value = convertTime(savedValue);
                } else if (!element.hasAttribute("data-not-autofill-values")) {
                  element.value = savedValue;
                }
                if (element.parentNode.classList.contains("grow-with-content") || element.parentNode.classList.contains("grow-with-input")) {
                  element.parentNode.dataset.replicatedValue = savedValue;
                }
                if (element.hasAttribute("data-setdayofweek") && i === 0) {
                  setDayOfWeek({
                    name: element.name,
                    value: element.value,
                  });
                }
                if (element.hasAttribute("data-linked-value") && savedValue) {
                  // console.log("element has linked value... setting", ele);
                  const ele = document.querySelector(`[name="${element.dataset.linkedValue}"][data-linked-value="${key}"]`);
                  if (ele) {
                    ele.value = savedValue;
                    ele.checked = true;
                    if (ele.hasAttribute("data-cb-group")) {
                      // Note:- if name and data-cb-group=? is equal then only it will work, otherwise need to remove👇this [data-cb-group="${ele?.name}"] from below
                      const groupElements = document.querySelectorAll(`[name="${ele?.name}"][data-cb-group="${ele?.name}"][type="checkbox"]`);
                      groupElements.forEach((e) => {
                        if (e.value !== ele.value) {
                          // console.log("elements to be unchecked", e);
                          e.checked = false;
                        }
                      });
                    }
                  }
                }
                if (element.hasAttribute("data-enable-target") && savedValue) {
                  document.querySelectorAll(`[data-enable-source="${element.dataset?.enableTarget}"][type="checkbox"]`).forEach((ele) => {
                    ele.checked = true;
                  });
                }

                if (element.classList.contains("formatPhoneNumber") && savedValue) {
                  element.value = formatPhoneNumber(element.value);
                }
              }
            });
          }
        }
      }
    }

    // To Show the Agent's Office details
    if (!["TR", "TO"].includes(rootDocData?.contractType) && rootDocData?.builtForSection !== "TEMPLATE") {
      showCompanyDetails(td, rootDocData);
    }
    //For closing company
    if (rootDocData?.contractType === "CI") {
      let signedTitleCompany = rootDocData?.signatureInfo?.find((ele) => ele.roleType === "TITLE_COMPANY");
      let closingCompany = { key: signedTitleCompany?.key || rootDocData?.email };
      td.TitleCompanyName && (closingCompany.fullName = td.TitleCompanySignedBy || rootDocData.fullName);
      let ClosingCompanySigBlock = buildSignatureBlocks([closingCompany], "Authorized", rootDocData, "TITLE_COMPANY", contractData);
      document.querySelectorAll('[data-signature-block="CLOSING_COMPANY"]')?.forEach((element) => {
        element.innerHTML = ClosingCompanySigBlock;
      });
      const signerTitleInputField = document.getElementById("SignerTitleField");
      if (signerTitleInputField) {
        signerTitleInputField.value = td?.SignerTitleInput || "";
      }
    }

    // persisting the signatures
    if (rootDocData && rootDocData.signatureInfo) {
      rootDocData.signatureInfo?.forEach((signatureBlock) => {
        handleSignatureCompletion(signatureBlock);
      });
    }
  }
  // autofill the document date
  const dateFields = document.querySelectorAll('[name="DocumentDate"], [name="OriginalContractDate"]');
  if (td && ((!td["DocumentDate"] && !createOfferContractTypes.includes(rootDocData?.contractType)) || !td["OriginalContractDate"]) && rootDocData?.builtForSection !== "TEMPLATE") {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const today = `${currentDate.getFullYear()}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
    dateFields?.forEach((el) => {
      const fieldName = el.name;
      if (fieldName === "OriginalContractDate" && (createOfferContractTypes.includes(rootDocData?.contractType) || ["ERTB", "ERTL", "ETCT"].includes(rootDocData?.contractType))) {
        if (!td[fieldName]) {
          td[fieldName] = today;
          el.value = today;
        }
      } else if (fieldName === "DocumentDate") {
        if (!td[fieldName]) {
          td[fieldName] = today;
          el.value = today;
        }
      }
    });
    localStorage.setItem(currentUrlId, JSON.stringify(td));
  }

  // adjusting widths of the price table inputs
  const priceTableinputs = document.querySelectorAll("[data-calculatepricetable]");
  if (priceTableinputs && priceTableinputs.length) {
    let maxWidth = 0;
    // Calculating the maximum width among all input values
    priceTableinputs?.forEach((input) => {
      const tempSpan = document.createElement("span");
      tempSpan.style.position = "absolute";
      tempSpan.style.visibility = "hidden";
      tempSpan.style.whiteSpace = "nowrap";
      tempSpan.textContent = input.value || "";
      document.body.appendChild(tempSpan);
      const computedWidth = tempSpan.offsetWidth + 2; // padding
      maxWidth = Math.max(maxWidth, computedWidth);
      document.body.removeChild(tempSpan);
    });
    // Applying the maximum width to all inputs
    priceTableinputs.forEach((input) => {
      input.style.width = `${maxWidth}px`;
    });
  }
};

export default SetInitialValues;
