import { Button, Checkbox, Modal } from 'antd';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styles from "../styles/FilterModal.module.css";
import { calendarHandle } from '../state/actions';

export default function FilterModal({setModalStates, handleCurrentMonth}) {
    let { calenderMonthFilter } = useSelector((state) => state.calendarData);
    const dispatch = useDispatch();

    const handleAgentClick = (data) => {
        dispatch(calendarHandle.handleSelectingAgents(data));
    }

    const handleDisabled = () => {
        let disabled = true;
        for(let element of calenderMonthFilter){
            if(element.selected){
                disabled = false;
                break;
            }
        }
        return disabled;
    }
    return (
        <div>
            <Modal
                title={<div className={styles.filterTitle}>FILTER BY AGENTS</div>}
                visible={true}
                closable
                onCancel={() => setModalStates(prev => ({ ...prev, openFilterModal: false }))}
                footer={null}
                style={{marginTop: "-70px"}}
                bodyStyle={{ padding: 0 }}
            >
                <div style={{padding: "10px"}}>
                    <div className={styles.agentCardParent}>
                        {calenderMonthFilter?.map((agent, index) => (
                            <div className={styles.agentCard}>
                                <div key={index} onClick={()=>handleAgentClick({...agent, selected: !agent.selected})}>
                                    <Checkbox checked={agent.selected}/> <span style={{fontWeight: "bolder"}}>{agent.fullName}</span> - <span style={{fontWeight: "500"}}>{agent.email}</span>
                                </div>
                            </div>
                        ))}
            
                    </div>
                    <div style={{display: "flex", justifyContent: "center", marginTop: "5px" }}>
                        <Button type='primary' disabled={handleDisabled()} onClick={handleCurrentMonth}>Apply</Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
