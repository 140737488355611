import { netSheetAdditionalItems } from "../../../Common/commondata/commonData";

export const addNewLoanComponent = ({ event, loanKey, loanValue, source }) => {
  let valueObj = {};
  const preEquityGroupTable = document.getElementById("preEquityGroupTable");
  if (preEquityGroupTable) {
    if (source === "setInitialValues") {
    } else {
      const currentRowCount = preEquityGroupTable.getElementsByTagName("tr").length;
      const newLoanNumber = currentRowCount + 1;
      valueObj[`Loan${newLoanNumber}`] = "";
      preEquityGroupTable.innerHTML += `
                <tr class="priceLabel">
                    <td style="width: 75%;">
                        Loan${newLoanNumber}:
                    </td>
                    <td>
                        <span class="priceTd tb">
                            $<span class="grow-with-input"><input type="text"
                                    class="grow currency inputBoxOutlineBorder" name="Loan${newLoanNumber}" size="8" data-preEquityGroup /></span>
                        </span>
                    </td>
                </tr>
            `;
    }
  }
  return valueObj;
};

export const addNewItem = ({ selectedItems, selectedItemsAndValuesContainer, source }) => {
  let valueObj = {};
  const postEquityGroupTable = document.getElementById("postEquityGroupTable");
  if (source === "setInitialValues" && selectedItemsAndValuesContainer?.length && postEquityGroupTable) {
  } else if (selectedItems?.length && postEquityGroupTable) {
    const newRows = selectedItems
      .map((item, index) => {
        // valueObj[`${item?.name}InPercentage`] = "";
        valueObj[item?.name] = "";
        return `
                <tr class="priceLabel" key="${item?.name}">
                    <td style="width: 75%;">
                        <div class="postEquityItemsFlex">
                            <span>${item?.label}</span>
                        </div>
                    </td>
                    <td>
                        <span class="priceTd tb">
                            $<span class="grow-with-input">
                                <input type="text" class="grow currency inputBoxOutlineBorder" name="${item?.name}" size="8" />
                            </span>
                        </span>
                    </td>
                </tr>
            `;
      })
      .join("");
    postEquityGroupTable.innerHTML += newRows;

    return valueObj;
  }
};

export const addCustomBlankInputItem = ({ customFieldCount, source, blankItemLabelKeys, blankItemFeeAmtKeys }) => {
  let valueObj = {};
  const postEquityGroupTable = document.getElementById("postEquityGroupTable");
  if (source === "setInitialValues" && customFieldCount && postEquityGroupTable) {
  } else if (customFieldCount && postEquityGroupTable) {
    const currentRowCount = postEquityGroupTable.getElementsByTagName("tr").length;
    for (let i = 1; i <= customFieldCount; i++) {
      const newRowNo = currentRowCount + i;
      valueObj[`AdditionalFee${newRowNo}`] = "";
      valueObj[`AdditionalFeeAmt${newRowNo}`] = "";
      // valueObj[`AdditionalFee${newRowNo}InPercentage`] = '';
      const newBlankRows = `
                <tr class="priceLabel">
                    <td style="width: 75%;">
                        <div class="postEquityItemsFlex">
                            <span class="grow-with-input">
                                <input type="text" class="grow inputBoxOutlineBorder" name="AdditionalFee${newRowNo}" data-postEquityGroupLabel size="40" />
                            </span>
                        </div>
                    </td>
                    <td>
                        <span class="priceTd tb">
                            $<span class="grow-with-input">
                                <input type="text" class="grow currency inputBoxOutlineBorder" name="AdditionalFeeAmt${newRowNo}" data-postEquityGroupInput size="8" />
                            </span>
                        </span>
                    </td>
                </tr>
            `;
      postEquityGroupTable.innerHTML += newBlankRows;
    }
  }
  return valueObj;
};

const checkForLoans = (td={}) => {
  const loanKeys = Object.keys(td).filter((key) => /^Loan\d+$/.test(key) && key !== "Loan1");
  const preEquityGroupTable = document.getElementById("preEquityGroupTable");
  if (preEquityGroupTable) {
    // Removing the existing dynamic rows (except for Loan1)
    const existingRows = Array.from(preEquityGroupTable.querySelectorAll("tr.priceLabel"));
    existingRows.forEach((row, index) => {
      if (index > 0) row.remove(); // Keeping the first row (Loan1)
    });

    loanKeys?.forEach((loanKey) => {
      const row = document.createElement("tr");
      row.classList.add("priceLabel");
      row.innerHTML = `
                  <td style="width: 75%;">${loanKey}:</td>
                  <td>
                      <span class="priceTd tb">
                          $<span class="grow-with-input"><input type="text"
                              class="grow currency inputBoxOutlineBorder" name="${loanKey}" size="8" data-preEquityGroup /></span>
                      </span>
                  </td>
              `;
      preEquityGroupTable.appendChild(row);
    });
  }
};

const checkForAdditionalFees = (td={}) => {
  let selectedItemsAndValuesContainer = [];
  netSheetAdditionalItems?.forEach((item) => {
    if (td.hasOwnProperty(item.name)) {
      selectedItemsAndValuesContainer.push({
        item,
        value: td[item.name] || "",
        // percentage: td[`${item.name}InPercentage`] || "",
      });
    }
  });
  if (selectedItemsAndValuesContainer.length) {
    const postEquityGroupTable = document.getElementById("postEquityGroupTable");
    if (postEquityGroupTable) {
      postEquityGroupTable.innerHTML = ""; // Clearing existing rows
      selectedItemsAndValuesContainer.forEach(({ item: { name, label }, value }) => {
        const row = document.createElement("tr");
        row.classList.add("priceLabel");
        row.innerHTML = `
                    <td style="width: 75%;">
                        <div class="postEquityItemsFlex">
                            <span>${label}</span>
                        </div>
                    </td>
                    <td>
                        <span class="priceTd tb">
                            $<span class="grow-with-input">
                                <input type="text" class="grow currency inputBoxOutlineBorder" name="${name}" size="8" value="${value}" />
                            </span>
                        </span>
                    </td>
                `;
        postEquityGroupTable.appendChild(row);
      });
    }
  }
};

const checkForBlankItems = (td = {}) => {
  const blankItemLabelKeys = Object.keys(td)?.filter((key) => /^AdditionalFee\d+$/.test(key));
  const blankItemFeeAmtKeys = Object.keys(td)?.filter((key) => /^AdditionalFeeAmt\d+$/.test(key));
  const maxRows = Math.max(blankItemLabelKeys?.length, blankItemFeeAmtKeys?.length);

  const postEquityGroupTable = document.getElementById("postEquityGroupTable");
  if (postEquityGroupTable && maxRows > 0) {
    for (let i = 0; i < maxRows; i++) {
      const labelKey = blankItemLabelKeys[i] || "";
      const amtKey = blankItemFeeAmtKeys[i] || "";
      const row = document.createElement("tr");
      row.classList.add("priceLabel");
      row.innerHTML = `
                  <td style="width: 75%;">
                      <div class="postEquityItemsFlex">
                          <span class="grow-with-input">
                              <input type="text" class="grow inputBoxOutlineBorder" name="${labelKey}" size="40" data-postEquityGroupLabel />
                          </span>
                      </div>
                  </td>
                  <td>
                      <span class="priceTd tb">
                          $<span class="grow-with-input">
                              <input type="text" class="grow currency inputBoxOutlineBorder" name="${amtKey}" size="8" data-postEquityGroupInput />
                          </span>
                      </span>
                  </td>
              `;
      postEquityGroupTable.appendChild(row);
    }
  }
};

export const checkDynamicFieldsOfNetSheet = (td) => {
  checkForLoans(td);
  checkForAdditionalFees(td);
  checkForBlankItems(td);
};
