import React, { useRef } from "react";
import ReactQuill from "react-quill";
import CustumToolbar, { formats, modules } from "./CustumToolbar.js";
import { useMediaQuery } from "react-responsive";
const ContentEditable = ({ setEmail_templatevisible, selectedtext, handleInput, emailList, setEmailList, isSmallScreen, isMediumScreen }) => {
  const quillRef = useRef();
  const breakPoint = useMediaQuery({ maxWidth: "573px" });
  return (
    <>
      {" "}
      <div
        style={{
          marginTop: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h3
            style={{
              marginBottom: "3px",
              paddingBottom: 0,
              fontSize: isSmallScreen ? "14px" : isMediumScreen ? "16px" : "18px",
            }}
          >
            Please Enter your message
          </h3>
          <button
            style={{
              borderRadius: "8px",
              marginBottom: "10px",
              cursor: "pointer",
              backgroundColor: "#2171b9",
              color: "white",
              border: "none",
              fontSize: isSmallScreen ? "12px" : isMediumScreen ? "14px" : "16px",
            }}
            onClick={() => {
              setEmail_templatevisible(true);
            }}
          >
            Email Template
          </button>
        </div>
        <div style={breakPoint ? { height: "200px" } : { height: "250px", border: "1px solid grey" }}>
          <CustumToolbar toolbarId={"editTemp"} />
          <ReactQuill
            className="editTemplate"
            placeholder="Enter message..."
            value={emailList?.emailMessage || selectedtext || ""}
            modules={modules}
            formats={formats}
            style={{ height: "83%", marginBottom: "100px" }}
            ref={quillRef}
            onChange={(value) => {
              setEmailList({
                emailMessage: value,
              });
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ContentEditable;
