import { fjggConstants } from "./action-types";
import { fjggApis } from "../utils/api";
import { message } from "antd";

// get access for FJGG Doc & clauses
const getFJGGAccessLoading = () => {
    return {
      type: fjggConstants.GET_ACCESS_DATA_LOADING,
    };
  };
  const getFJGGAccessError = (error) => {
    return {
      type: fjggConstants.GET_ACCESS_DATA_FAILURE,
      FJGGAccessDataError: error?.response?.data?.message || error?.message || "We encountered an error while fetching data, Please try again!",
    };
  };

  const getFJGGAccessSuccess = (response) => {
    return {
      type: fjggConstants.GET_ACCESS_DATA_SUCCESS,
      FJGGAccessData: response?.data?.info,
    };
  };

  const getFJGGAccess = (query) => {
    return (dispatch, getState) => {
      dispatch(getFJGGAccessLoading());
        fjggApis
        .getFJGGAccessRequest(query)
        .then((response) => {
          dispatch(getFJGGAccessSuccess(response));
        })
        .catch((error) => {
          dispatch(getFJGGAccessError(error));
        });
    };
  };

  // grant access for FJGG Doc & clauses
const grantFJGGAccessLoading = () => {
    return {
      type: fjggConstants.GRANT_ACCESS_TO_FJGG_LOADING,
    };
  };
  const grantFJGGAccessError = (error) => {
    message.error(error?.response?.data?.message || error?.message || "We encountered an error while granting access, Please try again!");
    return {
      type: fjggConstants.GRANT_ACCESS_TO_FJGG_FAILURE,
    };
  };

  const grantFJGGAccessSuccess = (response,dispatch) => {
    message.success(response?.data?.info || "Access granted successfully!");
    dispatch(getFJGGAccess())
    return {
      type: fjggConstants.GRANT_ACCESS_TO_FJGG_SUCCESS,
      FJGGAccessData: response?.data?.info,
    };
  };

  const grantFJGGAccess = (data) => {
    return (dispatch, getState) => {
      dispatch(grantFJGGAccessLoading());
        fjggApis
        .grantFJGGAccessRequest(data)
        .then((response) => {
          dispatch(grantFJGGAccessSuccess(response,dispatch));
        })
        .catch((error) => {
          dispatch(grantFJGGAccessError(error));
        });
    };
  };

const checkFJGGAccessAndVersionsAvailableLoading = () => {
  return {
    type: fjggConstants.CHECK_FJGG_ACCESS_AND_VERSIONS_AVAILABLE_LOADING,
  };
}

const checkFJGGAccessAndVersionsAvailableSuccess = (response) => {
  return {
    type: fjggConstants.CHECK_FJGG_ACCESS_AND_VERSIONS_AVAILABLE_SUCCESS,
    fJGGCheckData: response?.data?.info,
  };
}

const checkFJGGAccessAndVersionsAvailableError = (error) => {
  return {
    type: fjggConstants.CHECK_FJGG_ACCESS_AND_VERSIONS_AVAILABLE_FAILURE,
    fJGGAccessDataAndVersionCheckError: error?.response?.data?.message || error?.message || "We encountered an error while checking access, Please try again!",
  };
}

const checkFJGGAccessAndVersionsAvailable = (data) => {
  return (dispatch, getState) => {
    dispatch(checkFJGGAccessAndVersionsAvailableLoading());
    fjggApis
      .checkFJGGAccessAndVersionsAvailableRequest(data)
      .then((response) => {
        dispatch(checkFJGGAccessAndVersionsAvailableSuccess(response));
      })
      .catch((error) => {
        dispatch(checkFJGGAccessAndVersionsAvailableError(error));
      });
  };
}

export const fjggDataApis = {
    getFJGGAccess,
    grantFJGGAccess,
    checkFJGGAccessAndVersionsAvailable,
};
