import accounting from "accounting";
import { formatAndParse } from "../../../Common/utils/universalFuntions";

export const calculateFinalNetSheetPrice = ({td}) => {
    let PurchasePrice = formatAndParse(td['PurchasePrice']);

    // Pre-Equity group
    const loanKeys = Object.keys(td).filter((key) => /^Loan\d+$/.test(key) && key !== "Loan1");
    let totalLoans = loanKeys?.reduce((acc, key) => acc + formatAndParse(td[key]), 0) || 0;

    let GrossEquity = PurchasePrice - totalLoans - formatAndParse(td['Loan1']);

    // Post-Equity group
    let ListingBrokerCommission = formatAndParse(td['ListingBrokerCommission']) || 0;
    let SellingBrokerCommission = formatAndParse(td['SellingBrokerCommission']) || 0;
    let TitlePolicy = formatAndParse(td['TitlePolicy']) || 0;
    let EscrowFee = formatAndParse(td['EscrowFee']) || 0;
    let TransferTax = formatAndParse(td['TransferTax']) || 0;
    let TaxService = formatAndParse(td['TaxService']) || 0;
    let HomeWarranty = formatAndParse(td['HomeWarranty']) || 0;
    let ReleaseFee = formatAndParse(td['ReleaseFee']) || 0;
    let CourierFee = formatAndParse(td['CourierFee']) || 0;

    // Pre set Items
    let FirstMortgage = formatAndParse(td['FirstMortgage']) || 0;
    let SecondMortgage = formatAndParse(td['SecondMortgage']) || 0;
    let TaxProration = formatAndParse(td['TaxProration']) || 0;
    let TransactionManagementFee = formatAndParse(td['TransactionManagementFee']) || 0;
    let BackTaxes = formatAndParse(td['BackTaxes']) || 0;
    let WireFees = formatAndParse(td['WireFees']) || 0;
    let DiscountPaidBySeller = formatAndParse(td['DiscountPaidBySeller']) || 0;
    let HOADues = formatAndParse(td['HOADues']) || 0;
    let HOATransferFee = formatAndParse(td['HOATransferFee']) || 0;
    let Miscellaneous = formatAndParse(td['Miscellaneous']) || 0;
    let PrepaymentPenalty = formatAndParse(td['PrepaymentPenalty']) || 0;
    let Recording = formatAndParse(td['Recording']) || 0;
    let WaterEscrow = formatAndParse(td['WaterEscrow']) || 0;

    // Blank Items
    const additionalFeeKeys = Object.keys(td).filter((key) => /^AdditionalFeeAmt\d+$/.test(key));
    let totalAdditionalFees = additionalFeeKeys?.reduce((acc, key) => acc + formatAndParse(td[key]), 0) || 0;

    let totalPostEquityGroup = ListingBrokerCommission + SellingBrokerCommission + TitlePolicy + EscrowFee + TransferTax + TaxService + HomeWarranty + ReleaseFee + CourierFee + FirstMortgage + SecondMortgage + BackTaxes + WireFees + DiscountPaidBySeller + HOADues + HOATransferFee + Miscellaneous + PrepaymentPenalty + Recording + WaterEscrow + totalAdditionalFees + TaxProration + TransactionManagementFee;
    let TotalEstimateCosts = totalPostEquityGroup;

    let TotalEstimateNetProceeds = GrossEquity - TotalEstimateCosts;

    return {
        TotalEstimateNetProceeds: accounting.formatNumber(TotalEstimateNetProceeds, 2),
        GrossEquity: accounting.formatNumber(GrossEquity, 2),
        TotalEstimateCosts: accounting.formatNumber(TotalEstimateCosts, 2),
    };
}
