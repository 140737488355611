// import days from "dayjs";
var dayjs = require("dayjs");
let CryptoJS = require("crypto-js");
const { message } = require("antd");

const googleFonts = ["Great Vibes", "Cedarville Cursive", "Calligraffitti", "Allura", "Pacifico", "Dancing Script", "Whisper"];

let optionsForSort = [
  "PRICE",
  "CLOSING_DATE",
  "DOWN_PAYMENT_AMOUNT",
  "LOAN_TYPE",
];

const listingOrClientContractTypes = ["ERTS", "ERTB", "DWR", "BDB", "BDS", "LCAE", "ERTL", "ETCT", "LCAES_ERTL", "LCAES_ETCT", "FRASCONA_BDB", "WFD"];
const customerDocsContractTypes = ["DWR", "BDS", "BDB", "ERTB", "ERTL", "ETCT", "LCAE","LCAES_ETCT","LCAES_ERTL", "FRASCONA_BDB", "WFD"];
const createOfferContractTypes = ["CBSR", "CBS_MH", "CBS_LAND", "CBS_CL", "CBS_IR", "CBS_COFPA"];
const noChangeCheckboxContractTypes = ["ATAE", "ATAES", "CO", "MH_ATAE", "MH_ATAES", "MH_CO"];
const amendExtendContractTypes = ["ATAE", "ATAES", "MH_ATAE", "MH_ATAES"];
const conterOfferContractTypes = ["CO", "MH_CO"];
let loanTypes = ["CONVENTIONAL", "FHA", "VA", "BOND", "OTHER LOAN LIMITATIONS"];
let optionsForOrder = ["ASC", "DEC"];


var daysData = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

// let dateChangeRequest = (request) => {
//   var options = { year: "numeric", month: "numeric", day: "numeric" };
//   let d = new Date(request);
//   let updateDate = new Date(d.setDate(d.getDate() - 1)).toLocaleDateString(
//     "en-US",
//     options
//   );
//   let dd = new Date(updateDate);
//   if (
//     daysData[dd.getDay()] === "Sunday" ||
//     daysData[dd.getDay()] === "Saturday"
//   ) {
//     updateDate = new Date(d.setDate(d.getDate() - 1)).toLocaleDateString(
//       "en-US",
//       options
//     );
//   }

// };

let getDateData = (dateChange) => {
  var options = { year: "numeric", month: "numeric", day: "numeric" };
  let d = new Date(dateChange);
  // dateChangeRequest(dateChange);
  let updateDate = new Date(d.setDate(d.getDate() - 1)).toLocaleDateString(
    "en-US",
    options
  );
  const date = new Date(updateDate);
  // let exceptDay = daysData[date.getDate()];

  // if (exceptDay === "Sunday" || exceptDay === "Saturday") {
  //   getDateData(updateDate);
  // } else {
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  day < 10 && (day = `0${day}`);
  month < 10 && (month = `0${month}`);

  return `${year}-${month}-${day}`;
  // }
};


let rowDatakeys = [
  "Purchase Price",
  "Earnest Money",
  "New Loan",
  "Assumption Balance",
  "Private Financing",
  "Seller Financing",
  "Cash at Closing",
  "Price",
  "Total Amount",
];

//calculates the purchase price at the time of onCHange Event
let purchaseNames = (text) => {
  let rowData = {
    "Purchase Price": true,
    "Earnest Money": true,
    "New Loan": true,
    "Assumption Balance": true,
    "Private Financing": true,
    "Seller Financing": true,
    "Cash at Closing": true,
    Price: true,
    "Total Amount": true,
  };

  return rowData[text] || false;
};

let doubleDigitNumber = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
];


let convertDate = (date, source,key) => {
 
  if (date && date !=="undefined") {
    if(key==="Date Submitted"){
    return dayjs(date).format("MM/DD/YYYY hh:mm:ss A");
    }
    const parsedDate = dayjs(date);
    if (parsedDate.isValid()) {
      return parsedDate.format("MM/DD/YYYY");
    } else {
      return date;
    }
  } else {
    return source= "OFFER_TABLE"?"N/A":"";
  }
};
 // convert the time format into the 12h format
 function convertTime(time) {
  if (time && time !== "undefined") {
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) { 
      time = time.slice(1); 
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjusting hours
    }
    return time.join(''); 
  } else {
    return "";
  }
}



const formatPrice = (price, source) => {
  if (!price) {
    return source ? "0.00" : "";
  }
  const cleanedPrice = price.toString()?.replace(/[^0-9.-]/g, ""); //allowing the minus sign also
  // const cleanedPrice = price.toString()?.replace(/[^0-9.]/g, '')
  const numericPrice = parseFloat(cleanedPrice);
  if (isNaN(numericPrice) || !numericPrice) {
    return source ? "0.00" : "";
  }
  const priceString = numericPrice.toString();
  const hasDecimal = priceString.includes(".");
  // Adding a minus sign for negative values
  const formattedPrice =
    (numericPrice < 0 ? "-" : "") +
    (hasDecimal
      ? Math.abs(numericPrice)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      : Math.abs(numericPrice)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ".00");
  return formattedPrice;
};

let loanTypeData = ["Conventional", "FHA", "VA", "Bond"];


let encryptData = (data) => {
  return CryptoJS.AES.encrypt(data, 'url resure encryption').toString();
}

const Table31Inputs = [
  "TimeOfDayDeadline",
  "AlternativeEarnestMoneyDeadline",
  "RecordTitleDeadline",
  "RecordTitleObjectionDeadline",
  "OffRecordTitleDeadline",
  "OffRecordTitleObjectionDeadline",
  "TitleResolutionDeadline",
  "ThirdPartyRightToPurchaseApproveDeadline",
  "AssociationDocumentsDeadline",
  "AssociationDocumentsTerminationDeadline",
  "SellersPropertyDisclosureDeadline",
  "LeadBasedPaintDisclosureDeadline",
  "NewLoanApplicationDeadline",
  "NewLoanTermsDeadline",
  "NewLoanAvailabilityDeadline",
  "BuyersCreditInformationDeadline",
  "DisapprovalOfBuyersCreditInformationDeadline",
  "ExistingLoanDeadline",
  "ExistingLoanTerminationDeadline",
  "LoanTransferApprovalDeadline",
  "SellerOrPrivateFinancingDeadline",
  "AppraisalDeadline",
  "AppraisalObjectionDeadline",
  "AppraisalResolutionDeadline",
  "NewILCOrNewSurveyDeadline",
  "NewILCOrNewSurveyObjectionDeadline",
  "NewILCOrNewSurveyResolutionDeadline",
  "WaterRightsExaminationDeadline",
  "MineralRightsExaminationDeadline",
  "InspectionTerminationDeadline",
  "InspectionObjectionDeadline",
  "InspectionResolutionDeadline",
  "PropertyInsuranceTerminationDeadline",
  "DueDiligenceDocumentsDeliveryDeadline",
  "DueDiligenceDocumentsObjectionDeadline",
  "DueDiligenceDocumentsResolutionDeadline",
  "ConditionalSaleDeadline",
  "LeadBasedPaintTerminationDeadline",
  "ClosingDate",
  "PossessionDate",
  "PossessionTime",
  "AcceptanceDeadlineDate",
  "AcceptanceDeadlineTime"
];

let dateData = [
  {
    type: "time",
    keyName: "TimeOfDayDeadline",
    canRemoved:true,
    time: "21:00",
  },
  {
    type: "date",
    keyName: "AlternativeEarnestMoneyDeadline",
    canRemoved:true,
    differnce: "1-3",
  },
  {
    type: "date",
    keyName: "RecordTitleDeadline",
    canRemoved:true,
    differnce: "7",
  },
  {
    type: "date",
    keyName: "RecordTitleObjectionDeadline",
    canRemoved:true,
    differnce: "10",
  },
  {
    type: "date",
    keyName: "OffRecordTitleDeadline",
    canRemoved:true,
    differnce: "7",
  },
  {
    type: "date",
    keyName: "OffRecordTitleObjectionDeadline",
    canRemoved:true,
    differnce: "10",
  },
  {
    type: "date",
    keyName: "TitleResolutionDeadline",
    canRemoved:true,
    differnce: "13-15",
  },
  // {
  //   type: "date",
  //   keyName: "ThirdPartyRightToPurchaseApproveDeadline",
  //   initialVal:"N/A"
  // },
  {
    type: "date",
    keyName: "AssociationDocumentsDeadline",
    canRemoved:true,
    differnce: "7-10",
  },
  {
    type: "date",
    keyName: "AssociationDocumentsTerminationDeadline",
    canRemoved:true,
    differnce: "10-13",
  },
  {
    type: "date",
    keyName: "SellersPropertyDisclosureDeadline",
    canRemoved:true,
    differnce: "1-3",
  },
  {
    type: "date",
    keyName: "LeadBasedPaintDisclosureDeadline",
    canRemoved:true,
    differnce: "1-3",
  },
  {
    type: "date",
    keyName: "NewLoanApplicationDeadline",
    canRemoved:true,
    differnce: "1-3",
  },
  {
    type: "date",
    keyName: "NewLoanTermsDeadline",
    canRemoved:true,
    differnce: "5-7",
  },
  {
    type: "date",
    keyName: "NewLoanAvailabilityDeadline",
    canRemoved:true,
    differnce: "25-30",
  },
  // {
  //   type: "date",
  //   keyName: "BuyersCreditInformationDeadline",
  //   // differnce: "5-7",
  //   initialVal:"N/A"
  // },
  // {
  //   type: "date",
  //   keyName:"DisapprovalOfBuyersCreditInformationDeadline",
  //   initialVal: "N/A",
  //   // differnce: "10-13",
  // },
  // {
  //   type: "date",
  //   keyName: "ExistingLoanDeadline",
  //   initialVal:"N/A"
  //   // differnce: "7-10",
  // },
  // {
  //   type: "date",
  //   keyName: "ExistingLoanTerminationDeadline",
  //   initialVal:"N/A"
  //   // differnce: "11-13",
  // },
  // {
  //   type: "date",
  //   keyName: "LoanTransferApprovalDeadline",
  //   initialVal:"N/A"
  //   // differnce: "20-25",
  // },
  // {
  //   type: "date",
  //   keyName: "SellerOrPrivateFinancingDeadline",
  //   initialVal:"N/A"
  //   // differnce: "30-40",
  // },
  {
    type: "date",
    keyName: "AppraisalDeadline",
    canRemoved:true,
    differnce: "14-18",
  },
  {
    type: "date",
    keyName: "AppraisalObjectionDeadline",
    canRemoved:true,
    differnce: "16-18",
  },
  {
    type: "date",
    keyName: "AppraisalResolutionDeadline",
    canRemoved:true,
    differnce: "20-23",
  },
  {
    type: "date",
    keyName: "NewILCOrNewSurveyDeadline",
    canRemoved:true,
    differnce: "14-18",
  },
  {
    type: "date",
    keyName: "NewILCOrNewSurveyObjectionDeadline",
    canRemoved:true,
    differnce: "18-21",
  },
  {
    type: "date",
    keyName: "NewILCOrNewSurveyResolutionDeadline",
    canRemoved:true,
    differnce: "21-24",
  },
  {
    type: "date",
    keyName: "WaterRightsExaminationDeadline",
    canRemoved:true,
    differnce: "10-13",
  },
  {
    type: "date",
    keyName: "MineralRightsExaminationDeadline",
    canRemoved:true,
    differnce: "10-13",
  },
  {
    type: "date",
    keyName: "InspectionTerminationDeadline",
    canRemoved:true,
    differnce: "7-10",
  },
  {
    type: "date",
    keyName: "InspectionObjectionDeadline",
    canRemoved:true,
    differnce: "11-14",
  },
  {
    type: "date",
    keyName: "InspectionResolutionDeadline",
    canRemoved:true,
    differnce: "15-18",
  },
  {
    type: "date",
    keyName: "PropertyInsuranceTerminationDeadline",
    canRemoved:true,
    differnce: "14-18",
  },
  {
    type: "date",
    keyName: "DueDiligenceDocumentsDeliveryDeadline",
    canRemoved:true,
    differnce: "5-7",
  },
  {
    type: "date",
    keyName: "DueDiligenceDocumentsObjectionDeadline",
    canRemoved:true,
    differnce: "14-18",
  },
  {
    type: "date",
    keyName: "DueDiligenceDocumentsResolutionDeadline",
    canRemoved:true,
    differnce: "18-21",
  },
  {
    type: "date",
    keyName: "ConditionalSaleDeadline",
    canRemoved:true,
    differnce: "28",
  },
  {
    type: "date",
    keyName: "LeadBasedPaintTerminationDeadline",
    canRemoved:true,
    differnce: "14-18",
  },
  // {
  //   type: "date",
  //   keyName: "ClosingDate",
  //   canRemoved:false,
  //   differnce: "30-40",
  // },
  {
    type: "date",
    keyName: "PossessionDate",
    canRemoved:true,
    differnce: "30-40",
  },
  {
    type: "time",
    keyName: "PossessionTime",
    canRemoved:true,
    time: "21:00",
  },
  {type:"date", keyName:"AcceptanceDeadlineDate", canRemoved:true, differnce: "1"},
  {type:"time",keyName:"AcceptanceDeadlineTime", canRemoved:true,  time: "21:00"},
];



let isPublicHoliday = [
  "2021-01-01",
  "2022-01-17",
  "2022-02-21",
  "2022-03-31",
  "2022-05-30",
  "2022-06-04",
  "2022-09-05",
  "2022-10-03",
  "2022-11-11",
  "2022-11-24",
  "2022-12-26",
];


const roleAccessFunc = (roleAccessData, section) => {
  try {
    if (!roleAccessData || typeof roleAccessData !== 'object') {
      return true;
    }
    return Object.values(roleAccessData).some((role) => role && role[section]);
  } catch (err) {
    // console.log(err);
    return false;
  }
};

const roleSelectOption = [
  { value: "TRIAL_USER", label: "TRIAL_USER" },
  { value: "AGENT", label: "AGENT" },
  { value: "USER", label: "USER" },
  { value: "REALTOR", label: "REALTOR" },
  { value: "CUSTOMER", label: "CUSTOMER" },
  { value: "TRANSACTION_COORDINATOR", label: "TRANSACTION_COORDINATOR" },
  // { value: "RESURE_SUPPORT", label: "RESURE_SUPPORT" },
  { value: "BROKERAGE_MANAGER", label: "BROKERAGE_MANAGER"}
];
const adminRoleSelectOptionFun = (adminRole) => {

  if (adminRole?.includes("SUPER_ADMIN")) {
    return [
      { value: "SUPER_ADMIN", label: "SUPER_ADMIN" },
      { value: "ADMIN_FRANCHISE", label: "ADMIN_FRANCHISE" },
      // { value: "ADMIN_OFFICE", label: "ADMIN_OFFICE" },
      { value: "ADMIN_BROKERAGE", label: "ADMIN_BROKERAGE" },
      { value: "ADMIN_TITLE_COMPANY", label: "ADMIN_TITLE_COMPANY" },
      { value: "ADMIN_TITLE_OFFICE", label: "ADMIN_TITLE_OFFICE" },
      { value: "RS_SUPPORT1", label: "RS_SUPPORT1" },
      { value: "RS_SUPPORT2", label: "RS_SUPPORT2" },
    ];
  } else if (!adminRole?.includes("SUPER_ADMIN") && adminRole?.includes("RS_SUPPORT1")) {
    return [
      { value: "ADMIN_FRANCHISE", label: "ADMIN_FRANCHISE" },
      // { value: "ADMIN_OFFICE", label: "ADMIN_OFFICE" },
      { value: "ADMIN_BROKERAGE", label: "ADMIN_BROKERAGE" },
      { value: "ADMIN_TITLE_COMPANY", label: "ADMIN_TITLE_COMPANY" },
      { value: "ADMIN_TITLE_OFFICE", label: "ADMIN_TITLE_OFFICE" },
      { value: "RS_SUPPORT1", label: "RS_SUPPORT1" },
      { value: "RS_SUPPORT2", label: "RS_SUPPORT2" },
    ];
  } else if (!adminRole?.includes("SUPER_ADMIN") && adminRole?.includes("RS_SUPPORT2")) {
    return [
      { value: "ADMIN_FRANCHISE", label: "ADMIN_FRANCHISE" },
      // { value: "ADMIN_OFFICE", label: "ADMIN_OFFICE" },
      { value: "ADMIN_BROKERAGE", label: "ADMIN_BROKERAGE" },
      { value: "ADMIN_TITLE_COMPANY", label: "ADMIN_TITLE_COMPANY" },
      { value: "ADMIN_TITLE_OFFICE", label: "ADMIN_TITLE_OFFICE" },
      { value: "RS_SUPPORT2", label: "RS_SUPPORT2" },
    ];
  } else {
    return []
  }
}

const commonStatusFunc = ({key}) => {
  switch (key) {
    case "ACCEPTED":
        return {value: "Pending", key: "UNDER_CONTRACT"};
    case "ARCHIVED":
        return {value: "Archived", key: "ARCHIVED"};
    case "DRAFT":
        return {value: "Draft", key: "DRAFT"};
    case "SUBMITTED":
    case "HOLD":
    case "BACKUP":
    case "INPROGRESS":
        return {value: "In-Progress", key: "INPROGRESS"};
    case "TERMINATED":
        return {value: "Terminated", key: "TERMINATED"};
    case "Active":
    case "Pending":
    case "Active Under Contract":
        return {value: "Active", key: "ACTIVE"};
    case "CLOSED":
    case "Closed":
        return {value: "Closed", key: "CLOSED"};
    default:
        return "";
  }
}


const checkIfDisabled = ({openFor, actualPropertyStatus, offerStatus, buyingStatus, sellingStatus, contractData, personStatus}) => {
  const enableArray = contractData?.documentaryId?.enableDuringDisableStatus;
  //person status
  if(personStatus === "ARCHIVE"){
    return {
      disabled: true
    }
  }

  //transaction status
  if((openFor === "SELLER") || (openFor === "SELLER_AGENT")){
    const common = commonStatusFunc({key: actualPropertyStatus});
    if(((common?.key === "CLOSED") && !enableArray?.includes(common?.key)) || ((common?.key === "ARCHIVED") && !enableArray?.includes(common?.key)) || ((sellingStatus === "HOLD") && !enableArray?.includes(sellingStatus)) || ((offerStatus === "TERMINATED") && !enableArray?.includes(offerStatus))){
      return {
        disabled: true
      }
    }
  }
  else if((openFor === "BUYER") || (openFor === "BUYER_AGENT")){
    const common = commonStatusFunc({key: buyingStatus});
    if(((common?.key === "CLOSED") && !enableArray?.includes(common?.key)) || ((common?.key === "ARCHIVED") && !enableArray?.includes(common?.key)) || ((offerStatus === "TERMINATED") && !enableArray?.includes(offerStatus))){
      return {
        disabled: true
      }
    }
  }
}

const disabledTransactionText = () => {
  message.warning("Action not available for transactions with status Closed, Archived and Terminated.");
}


const sellerBrokerFields = ["SellerBrokerAcknowledgeEarnestMoneyReceipt", "SellerBrokerWorkingAs", "SellerBrokerCustomerChecked", "SellerBrokerPaidBy", "SellerBrokerPaidBy__Other"]
const buyerBrokerFields = ["BuyerBrokerAcknowledgeEarnestMoneyReceipt", "BuyerBrokerWorkingAs", "BuyerBrokerCustomerChecked", "BuyerBrokerPaidBy", "BuyerBrokerPaidBy__Other"];
 
const dirSelectOptions = [
  { value: "", label: "None" },
  { value: "East", label: "East" },
  { value: "West", label: "West" },
  { value: "North", label: "North" },
  { value: "South", label: "South" },
  { value: "Northeast", label: "North East" },
  { value: "Northwest", label: "North West" },
  { value: "Southeast", label: "South East" },
  { value: "Southwest", label: "South West" },
];

const netSheetAdditionalItems = [
  // { id: 1, label: '1st Mortgage', name: 'FirstMortgage' },
  // { id: 2, label: '2nd Mortgage', name: 'SecondMortgage' },
  { id: 1, label: 'Tax Proration', name: 'TaxProration' },
  { id: 2, label: 'Transaction Management Fee', name: 'TransactionManagementFee' },
  { id: 3, label: 'Back taxes', name: 'BackTaxes' },
  { id: 4, label: 'Courier/Wire Fees', name: 'WireFees' },
  { id: 5, label: 'Discount Paid by Seller', name: 'DiscountPaidBySeller' },
  { id: 6, label: 'HOA Dues', name: 'HOADues' },
  { id: 7, label: 'HOA Transfer Fee', name: 'HOATransferFee' },
  { id: 8, label: 'Miscellaneous', name: 'Miscellaneous' },
  { id: 9, label: 'Prepayment Penalty', name: 'PrepaymentPenalty' },
  { id: 10, label: 'Recording', name: 'Recording' },
  { id: 11, label: 'Water Escrow', name: 'WaterEscrow' },
];

const netSheetFieldsHiddenToTitleCompany = ["PurchasePrice", "GrossEquity", "TotalEstimateNetProceeds"]

module.exports = {
  googleFonts,
  isPublicHoliday,
  listingOrClientContractTypes,
  createOfferContractTypes,
  convertDate,
  formatPrice,
  purchaseNames,
  daysData,
  getDateData,
  optionsForSort,
  loanTypes,
  optionsForOrder,
  doubleDigitNumber,
  loanTypeData,
  rowDatakeys,
  dateData,
  Table31Inputs,
  convertTime,
  roleAccessFunc,
  roleSelectOption,
  adminRoleSelectOptionFun,
  noChangeCheckboxContractTypes,
  conterOfferContractTypes,
  amendExtendContractTypes,
  checkIfDisabled,
  disabledTransactionText,
  encryptData,
  sellerBrokerFields,
  buyerBrokerFields,
  dirSelectOptions,
  netSheetAdditionalItems,
  netSheetFieldsHiddenToTitleCompany,
  customerDocsContractTypes
};
