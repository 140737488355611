import { contractConstants } from "./action-types";

const initialState = {
  contractData: null,
  contractLoading: null,
  contractError: null,

  currentUrlId: "",
  contractAuthKey: null,

  createOfferData: null,
  rootDocData: {},
  createOfferLoading: null,
  createOfferError: null,

  clausesData: null,
  clausesLoading: true,
  clausesError: null,

  loadingSave: false,
  error: false,

  setDrawer: false,

  setEmailData: null,
  setEmailError: false,
  setEmailLoading: false,

  agentDetailsData:{},
  propertyDocs: [],
  propertDocsError: false,
  propertyDataErrorMsg: null,
  propertDocsLoading: false,

  personDocs: [],
  personDocsLoading:false,
  personDocsError: null,
};

const createOffer = (state = initialState, action) => {
  switch (action.type) {
    case contractConstants.SET_URL_ID: {
      return {
        ...state,
        currentUrlId: action.currentUrlId,
      };
    }
    case contractConstants.SET_AUTH_KEY_FOR_CONTRACT: {
      return {
        ...state,
        contractAuthKey: action.contractAuthKey,
      };
    }
    case contractConstants.SET_READ_ONLY_DATA_FOR_CONTRACT: {
      let allRootData = {
        ...(state.rootDocData ? state.rootDocData : {}),
        ...(action.rootDocData ? action.rootDocData : {})
      }
      // console.log("allRootData", allRootData);
      return {
        ...state,
        rootDocData: allRootData,
      };
    }
   
    case contractConstants.CONTRACT_PAGE_SUCCESS:
      return {
        ...state,
        contractData: action.contractData,
        rootDocData: {
          ...(state.rootDocData || {}),
          signatureSequence: action?.contractData?.signatureSequence || {},
          footerText: action?.contractData?.footerText || "",
        },
        contractLoading: action.contractLoading,
        contractError: null,
      };
    case contractConstants.CONTRACT_PAGE_FAILURE:
      return {
        ...state,
        contractData: [],
        contractLoading: action.contractLoading,
        contractError: action.contractError,
      };
    case contractConstants.CREATE_OFFER_SUCCESS: {
      
      return {
        ...state,
        createOfferData: action.createOfferData,
        offerId:action.offerId,
        rootDocData: action.rootDocData,
        createOfferLoading: action.createOfferLoading,
        createOfferError: null,
      };
    }
    case contractConstants.CREATE_OFFER_FAILURE:
      return {
        ...state,
        createOfferData: [],
        createOfferLoading: action.createOfferLoading,
        createOfferError: action.createOfferError,
      };
    case contractConstants.RESET_COMPRESSED_VIEW:
      let urlId = state.currentUrlId;
      localStorage.removeItem(state.currentUrlId);
      return {
        ...initialState,
        rootDocData: state?.rootDocData,
        currentUrlId: urlId,
      };
    case contractConstants.SET_LOADING_SAVE:
      return {
        ...state,
        loadingSave: action.loadingSave,
      };
    case contractConstants.VERIFY_OTP_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case contractConstants.SET_DRAWER_POSITION:
      return {
        ...state,
        setDrawer: action.setDrawer,
      };
    case contractConstants.GET_EMAIL_CONTENT_SUCCESS:
      return {
        ...state,
        setEmailData: action.setEmailData,
      }
    case contractConstants.GET_EMAIL_CONTENT_LOADING:
      return {
        ...state,
        setEmailLoading: action.setEmailLoading,
      }
    case contractConstants.GET_EMAIL_CONTENT_ERROR:
      return {
        ...state,
        setEmailError: action.setEmailError,
      }
    case contractConstants.GET_AGENT_DETAILS_LOADING:
      return {
        ...state,
        agentDetailsLoading: action.agentDetailsLoading,
      }
    case contractConstants.GET_AGENT_DETAILS_SUCCESS:
      return {
        ...state,
        agentDetailsData: action.agentDetailsData,
      }
    case contractConstants.SET_AGENT_DETAILS_DATA:
      return {
        ...state,
        agentDetailsData: action.agentDetailsData,
      }
  
 // property docs methods
 case contractConstants.GET_CLIENT_DOC_LOADING:
  return {
    ...state,
    propertDocsLoading: true,
    propertDocsError: null,
  };
case contractConstants.GET_CLIENT_DOC_SUCCESS:
  return {
    ...state,
    propertyDocs: action.propertyDocs,
    propertDocsLoading: false,
    propertDocsError: null,
  };
case contractConstants.GET_CLIENT_DOC_ERROR:
  return {
    ...state,
    propertyDocs: [],
    propertDocsLoading: false,
    propertDocsError: null,
  };

case contractConstants.RESET_PROPERTY_DOCS:
    return {
      ...state,
      document: [],
      propertDocsLoading: false,
      propertDocsError: null,
    };
      //   Get person Docs
    case contractConstants.PERSON_DOCS_LOADING:
      return {
        ...state,
        personDocs: action.personDocs,
        personDocsLoading: true,
        globalClientLoading: true,
        personDocsError: false,
      };
    case contractConstants.PERSON_DOCS_SUCCESS:
      return {
        ...state,
        personDocs: action.personDocs,
        personDocsLoading: false,
        personDocsError: false,
      };
    case contractConstants.PERSON_DOCS_FAILURE:
      return {
        ...state,
        personDocsError: action.personDocsError,
        personDocsLoading: false,
      };
    default:
      return state;
  }
};
export { createOffer };
