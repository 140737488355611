import React from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal, message } from "antd";
import { ModalChild } from "./ModalChild";
import { handleSignatureCompletion, makeAllFieldsDisabled } from "../features/helperFunctions";
import axios from "axios";
import { contractStoreHandle } from "../../ContractStore/state/actions";
import styles from "../styles/HandleSignatureBox.module.css";
import { createOfferContractTypes } from "../../Common/commondata/commonData";
import { isAtleastOneClientPresent } from "../../OfferTable/utils/helper";
const ipURL = process.env.REACT_APP_GET_IP_URL;
const ipURLKey = process.env.REACT_APP_GET_IP_URL_KEY;

function HandleSignatureBox({ event, dispatch, contractAuthKey, currentUrlId, rootDocData }) {
  if (!rootDocData) {
    return null;
  }
  const { contractType, RTDInitiator, signatureSequence, openFor } = rootDocData;
  let localData = JSON.parse(localStorage.getItem(currentUrlId)) || {};

  if (contractType) {
    const buyerInfo = localData["Buyers"];
    const sellerInfo = localData["Sellers"];
    if (RTDInitiator === "BUYER") {
      if (signatureSequence?.buyer && !isAtleastOneClientPresent(buyerInfo)) {
        message.error(`At least one buyer is required in order to sign the document.`);
        return null;
      }
      if (["SELLER", "SELLER_AGENT"].includes(openFor) && signatureSequence?.seller && !isAtleastOneClientPresent(sellerInfo)) {
        message.error(`At least one seller is required in order to sign the document.`);
        return null;
      }
    }

    if (RTDInitiator === "SELLER") {
      if (signatureSequence?.seller && !isAtleastOneClientPresent(sellerInfo)) {
        const contractTypeMap = {
          ERTL: "landlord",
          ETCT: "tenant",
        };
        const requiredParty = contractTypeMap[rootDocData?.contractType] || "seller";
        message.error(`At least one ${requiredParty} is required in order to sign the document.`);
        return null;
      }
      if (!["SELLER", "SELLER_AGENT"].includes(openFor) && signatureSequence?.buyer && !isAtleastOneClientPresent(buyerInfo)) {
        message.error(`At least one buyer is required in order to sign the document.`);
        return null;
      }
    }

    if (createOfferContractTypes.includes(contractType)) {
      if (!localData["ClosingDate"] || isNaN(new Date(localData["ClosingDate"]).getTime())) {
        message.error("Please enter Closing Date in table 3.1 in order to sign the document.");
        return null;
      }
    }
    // Logic to throw error for "ERTB", "ERTS", "ERTL", "ETCT" doc if agent has not added starting date and expiry date
    if (["ERTB", "ERTS", "FRASCONA_ERTS", "ERTL", "ETCT"].includes(contractType) && (!localData["ListingPeriodBeginDate"] || !localData["ListingPeriodEndByDate"])) {
      message.error(`Please enter Listing Period in section ${contractType === "ERTS" ? "3.7" : "3.6"} in order to sign the document.`);
      return null;
    }
    // Throw error if user trying to sign without address
    if (["ERTS", "FRASCONA_ERTS"].includes(contractType) && !localData["FullAddress"]) {
      message.error("Please enter full address in order to sign the document.");
      return null;
    }
  }

  let ip;
  function handlePermission() {
    report();
  }

  function report(state) {
    const isAccess = document.getElementById("isAccess");
    if (isAccess) {
      isAccess.innerHTML = "<div class='dots-3'></div>";
      isAccess.style.display = "block";
    }
    showPosition();
  }

  setTimeout(() => {
    handlePermission();
  }, 100);

  function showPosition() {
    const isAccess = document.getElementById("isAccess");
    const isShow = document.getElementById("isShow");
    axios
      .get(`${ipURL}${ipURLKey}`)
      .then((response) => {
        ip = response.data;
        if (isShow) {
          isShow.style.display = "block";
        }
        if (isAccess) {
          isAccess.style.display = "none";
        }
      })
      .catch((error) => {
        // if (isAccess) {
        //   isAccess.innerHTML = "Something went wrong";
        //   isAccess.style.display = "block";
        // }
        if (isShow) {
          isShow.style.display = "none";
        }
      });
  }

  let handleCancel = () => {
    const isAccess = document.getElementById("isAccess");
    const isShow = document.getElementById("isShow");
    if (isAccess) {
      isAccess.style.display = "none";
    }
    if (isShow) {
      isShow.style.display = "none";
    }
    let getModal = document.getElementsByClassName("ant-modal-root")[0];
    let getbody = document.querySelector("body");
    getModal.parentNode.remove();
    getbody.style.overflow = "auto";
  };

  let handleOk = () => {};

  const handleYesButtonClick = () => {
    let confirmIPDiv = document.getElementById("confirmIPDiv");
    let signModalChild = document.getElementById("signModalChild");
    confirmIPDiv.style.display = "none";
    signModalChild.style.display = "block";
  };
  const saveFunc = (signCanvas, destination, initial) => {
    // let signFlag = destination === "text" ? false : signCanvas.current.isEmpty();
    let canvasImage = destination === "text" ? signCanvas : signCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    let timeStamp = new Date().getTime();
    let signerId = event?.target?.getAttribute("data-signerId");
    let signerKey = event?.target?.getAttribute("data-signerKey");

    if (!ip) {
      message.info("Sorry for the inconvenience. We encountered an issue capturing your IP Address and GPS Location. Please click the signature box again to sign the document.", 4);
      handleCancel();
      return null;
    }

    // adding signature data for respective openFor users
    let signatureBlock = {
      ip: ip,
      ...(signerKey ? { key: signerKey } : { key: signerId }),
      ...(rootDocData.openFor !== "TITLE_COMPANY" ? { _id: signerId } : { key: rootDocData.email }),
      roleType: rootDocData.openFor,
      sign: canvasImage,
      timeStamp: timeStamp,
      initials: initial,
    };
    //   console.log("inside this signature block", signatureBlock);
    handleSignatureCompletion(signatureBlock);
    handleCancel();
    if (rootDocData.openFor === "TITLE_COMPANY") {
      let getData = JSON.parse(localStorage.getItem(currentUrlId));
      localStorage.setItem(currentUrlId, JSON.stringify({ ...getData, TitleCompanySignedBy: rootDocData.fullName }));
    }
    dispatch(contractStoreHandle.autoSaveContract({ currentUrlId, contractAuthKey, signatureBlock, valueObj: rootDocData.openFor === "TITLE_COMPANY" ? { TitleCompanySignedBy: rootDocData.fullName } : {} }));
    // All fields disabled logic
    if (currentUrlId && rootDocData) {
      makeAllFieldsDisabled(currentUrlId, rootDocData);
    }
  };
  return (
    <Modal className={styles.signatureConfirmModal} id="changeModal11" visible={true} onOk={handleOk} mask={true} closable={true} okText={"Submit"} onCancel={handleCancel} footer={false}>
      <div>
        <div id="confirmIPDiv" className={styles.confirmIPDivContents}>
          <div className={styles.contentItems}>
            <div>
              <ExclamationCircleOutlined
                style={{
                  color: "#EE7600",
                  fontSize: "25px",
                  marginTop: "5px",
                }}
              />
            </div>
            <div>
              <h3>This is a legally binding contract and your signature will be embedded into this document</h3>
              <h4 style={{ marginTop: "20px", color: "#8F8f8f" }}>Note: We are capturing your IP Address and GPS Location for the security reasons. Please click on Yes to proceed!</h4>
            </div>
          </div>
          <div className={styles.confirmButtons}>
            <Button onClick={handleCancel}>No</Button>
            <Button type="primary" onClick={handleYesButtonClick}>
              Yes
            </Button>
          </div>
        </div>
        <div
          id="signModalChild"
          style={{
            display: "none",
          }}
          className={styles.addSignModal}
        >
          <ModalChild evnt={event} currentUrlId={currentUrlId} saveFunc={saveFunc} rootDocData={rootDocData} />
        </div>
      </div>
    </Modal>
  );
}

export default HandleSignatureBox;
