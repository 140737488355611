import { fjggConstants } from "./action-types";


const initialState = {
  FJGGAccessData : [],
  FJGGAccessDataLoading : false,
  FJGGAccessDataError : false,

  fJGGAccessDataAndVersionCheckError: false,
  fJGGAccessDataAndVersionCheckLoading: false,
  fJGGCheckData: {},
};

const fjggReducer = (state = initialState, action) => {
  switch (action.type) {
        case fjggConstants.GET_ACCESS_DATA_LOADING:
            return {
              ...state,
              FJGGAccessDataLoading:true,
            };
          case fjggConstants.GET_ACCESS_DATA_SUCCESS:
            return {
              ...state,
              FJGGAccessData:action.FJGGAccessData,
              FJGGAccessDataLoading:false,
              FJGGAccessDataError: false,
            };
          case fjggConstants.GET_ACCESS_DATA_FAILURE:
            return {
              ...state,
              FJGGAccessDataError:action.FJGGAccessDataError,
              FJGGAccessDataLoading:false,

            };

          case fjggConstants.CHECK_FJGG_ACCESS_AND_VERSIONS_AVAILABLE_LOADING:
            return {
              ...state,
              fJGGAccessDataAndVersionCheckLoading: true,
              fJGGCheckData: {},
              fJGGAccessDataAndVersionCheckError: false,
            };
          case fjggConstants.CHECK_FJGG_ACCESS_AND_VERSIONS_AVAILABLE_SUCCESS:
            return {
              ...state,
              fJGGCheckData: action.fJGGCheckData,
              fJGGAccessDataAndVersionCheckLoading: false,
              fJGGAccessDataAndVersionCheckError: false,
            };
          case fjggConstants.CHECK_FJGG_ACCESS_AND_VERSIONS_AVAILABLE_FAILURE:
            return {
              ...state,
              fJGGAccessDataAndVersionCheckError: action.fJGGAccessDataAndVersionCheckError,
              fJGGAccessDataAndVersionCheckLoading: false,
              fJGGCheckData: {},
            };

    default:
      return state;
  }
};

export { fjggReducer };
