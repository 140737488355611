import { transactionLaneConstants } from "./action-types";

const initialState = {
  getTransactionLaneData: null,
  getTransactionLaneLoading: false,
  getTransactionLaneError: null,
  updateDocumentLoading: false,
  updateDocumentSucess: null,
  updateDocumentFailure: null,
  data: [],
  getIndividualTransactionLoading: false,
  getIndividualTransactionError: false,
  getIndividualTransactionData: {},
  getIndividualTransactionErrorMsg: null,

  sendEmailLoading: false,
  sendEmailData: null,
  sendEmailError: false,

  getDoraDocumentsData: [],
  getDoraDocumentsLoading: false,
  getDoraDocumentsError: null,

  getEmail_TemplateData: [],
  getEmail_TemplateLoading: false,
  getEmail_TemplatesError: false,

  doraDocsUrlShorthandData: null,
  doraDocsUrlShorthandLoading: false,
  doraDocsUrlShorthandFailure: null,

  getSharedDocsData: [],
  getSharedDocsLoading: false,
  getSharedDocsError: null,

  addEmailLoading: false,
};

const rtdTransactionLane = (state = initialState, action) => {
  switch (action.type) {
    case transactionLaneConstants.GET_TRANSACTION_LANE_IN_RTD_LOADING:
      return {
        ...state,
        getTransactionLaneLoading: action.getTransactionLaneLoading,
        getTransactionLaneError: null,
      };

    case transactionLaneConstants.GET_TRANSACTION_LANE_IN_RTD_SUCCESS:
      return {
        ...state,
        getTransactionLaneData: action.getTransactionLaneData,
        getTransactionLaneLoading: false,
        getTransactionLaneError: null,
        data: action.getTransactionLaneData,
      };
    case transactionLaneConstants.GET_TRANSACTION_LANE_IN_RTD_FAILURE:
      return {
        ...state,
        getTransactionLaneData: [],
        getTransactionLaneLoading: action.getTransactionLaneLoading,
        getTransactionLaneError: action.getTransactionLaneError,
      };
    // Email Reducer
    case transactionLaneConstants.SEND_EMAIL_LOADING:
      return {
        ...state,
        sendEmailLoading: true,
        sendEmailError: false,
      };
    case transactionLaneConstants.SEND_EMAIL_SUCCESS:
      return {
        ...state,
        sendEmailData: action.sendEmailData,
        sendEmailLoading: false,
        sendEmailError: false,
      };
    case transactionLaneConstants.SEND_EMAIL_FAILURE:
      return {
        ...state,
        sendEmailLoading: false,
        sendEmailError: action.sendEmailError,
      };

    case transactionLaneConstants.UPDATE_DOCUMENT_LOADING:
      return {
        ...state,
        updateDocumentLoading: true,
        updateDocumentFailure: false,
      };

    case transactionLaneConstants.UPDATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        updateDocumentFailure: false,
        updateDocumentSucess: true,
        updateDocumentLoading: false,
        
      };


    case transactionLaneConstants.UPDATE_DOCUMENT_FAILURE:
      return {
        ...state,
        updateDocumentFailure: action.error,
        updateDocumentSucess: false,
        updateDocumentLoading: false,
      };
    // Dora Docs Reducer
    case transactionLaneConstants.GET_DORA_DOCUMENTS_LOADING:
      return {
        ...state,
        getDoraDocumentsLoading: true,
        getDoraDocumentsError: null,
      };
    case transactionLaneConstants.GET_DORA_DOCUMENTS_SUCCESS:
      let filterdDoraDocs = action.getDoraDocumentsData?.filter(el => el.contractType === "CO" || el.contractType === "MH_CO") || [];
      return {
        ...state,
        getDoraDocumentsData: filterdDoraDocs,
        getDoraDocumentsLoading: false,
        getDoraDocumentsError: null,
      };
    case transactionLaneConstants.GET_DORA_DOCUMENTS_FAILURE:
      return {
        ...state,
        getDoraDocumentsLoading: false,
        getDoraDocumentsError: null,
      };
    case transactionLaneConstants.GET_EMAIL_TEMPLATE_LOADING:
      return {
        ...state,
        getEmail_TemplateLoading: true,
        getEmail_TemplatesError: false,
      };
    case transactionLaneConstants.GET_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        getEmail_TemplateData: action.getEmail_Templatedata,
        getEmail_TemplateLoading: false,
        getEmail_TemplatesError: false,
      };
    case transactionLaneConstants.GET_EMAIL_TEMPLATE_FAILURE:
      return {
        ...state,
        getEmail_TemplateLoading: false,
        getEmail_TemplatesError: true,
      };

    case transactionLaneConstants.DORADOCS_SHORTHAND_URL_LOADING:
      return {
        ...state,
        doraDocsUrlShorthandLoading: true,
        doraDocsUrlShorthandFailure: false,
      };

    case transactionLaneConstants.DORADOCS_SHORTHAND_URL_SUCCESS:
      return {
        ...state,
        doraDocsUrlShorthandLoading: false,
        doraDocsUrlShorthandFailure: false,
      };
    case transactionLaneConstants.DORADOCS_SHORTHAND_URL_FAILURE:
      return {
        ...state,
        doraDocsUrlShorthandLoading: false,
        doraDocsUrlShorthandFailure: true,
      };
      case transactionLaneConstants.GET_SHARED_DOCS_LOADING:
      return {
        ...state,
        getSharedDocsLoading: true,
        getSharedDocsError: false,
      };
    case transactionLaneConstants.GET_SHARED_DOCS_SUCCESS:
      return {
        ...state,
        getSharedDocsData: action.getSharedDocsData,
        getSharedDocsLoading: false,
        getSharedDocsError: null,
      };
    case transactionLaneConstants.GET_SHARED_DOCS_FAILURE:
      return {
        ...state,
        getSharedDocsData: [],
        getSharedDocsLoading: false,
        getSharedDocsError: null,
      };
    case transactionLaneConstants.ADD_EMAIL_LOADING:
      return {
        ...state,
        addEmailLoading: true
      }
      case transactionLaneConstants.ADD_EMAIL_ERROR:
        return {
          ...state,
          addEmailLoading: false
        }
    case transactionLaneConstants.ADD_EMAIL_SUCCESS:
      return {
        ...state,
        addEmailLoading: false
      }
      case transactionLaneConstants.GET_INDIVIDUAL_TRANSACTION_LOADING:
      return {
        ...state,
        getIndividualTransactionLoading: true,
        getIndividualTransactionData: action.getIndividualTransactionData,
      };
      case transactionLaneConstants.GET_INDIVIDUAL_TRANSACTION_SUCCESS:
        return {
          ...state,
          getIndividualTransactionError: false,
          getIndividualTransactionLoading:false,
          getIndividualTransactionData: action.getIndividualTransactionData,
        };
      case transactionLaneConstants.GET_INDIVIDUAL_TRANSACTION_FAILURE:
        return {
          ...state,
          getIndividualTransactionError: true,
          getIndividualTransactionLoading:false,
          getIndividualTransactionErrorMsg: action.errorMsg,
          getIndividualTransactionData: []
        };
       case transactionLaneConstants.TRIGGER_INDIVIDUAL_TRANSACTION_FETCH:
        return {
          ...state,
          triggerIndividualTransactionFetch: []
        };
    default:
      return state;
  }
};
export { rtdTransactionLane };
