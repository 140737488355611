import { Checkbox } from "antd";
import React from "react";
import styles from "../style/SendEmailModal.module.css";

const GroupList = ({ data, groupKey, groupName, selectedData, onGroupSelect, onIndividualSelect }) => (
  <div style={{ marginBottom: "10px" }}>
    <div className={styles.flexContatiner} style={{ fontWeight: "bold" }}>
      <Checkbox
        onChange={(e) =>
          onGroupSelect({
            [`${groupKey}Data`]: e.target.checked ? data : [],
          })
        }
        checked={data.length === selectedData.length}
      />
      <div className={styles.subHeader}>{groupName}</div>
    </div>
    {/* <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        fontWeight: "bold",
        color: "grey",
      }}
    >
      <p className={styles.documentHeader}>{groupName}:</p>
      <Checkbox
        onChange={(e) =>
          onGroupSelect({
            [groupKey]: e.target.checked,
            [`${groupKey}Data`]: e.target.checked ? data : [],
          })
        }
        style={{ width: isSmallScreen ? "50%" : isMediumScreen ? "30%" : "15%" }}
        checked={data.length === selectedData.length}
      >
        <text className={styles.documentHeader}>Select All</text>
      </Checkbox>
    </div> */}
    {data.map((el) => (
      <div
        key={el._id}
        className={styles.signlePersonRow}
        style={{
          marginLeft: "28px",
        }}
      >
        <Checkbox checked={selectedData.some((selectedItem) => selectedItem._id === el._id)} onChange={() => onIndividualSelect(el, groupKey)} />
        <div>
          <span className={styles.fullName}>{el?.fullName}</span>
          <span className={styles.dash}>-</span>
          <text className={styles.email}>{el?.email}</text>
        </div>
      </div>
    ))}
  </div>
);

const TtileRepsAndAffiliatesList = ({ combinedTitleCompanyData, selectedPersonsState, setSelectedPersonsState, handleGroupCheckboxSelcted, isSmallScreen, isMediumScreen }) => {
  const { titleRepsData = [], affiliatesData = [] } = combinedTitleCompanyData || {};

  const handleIndividualChanges = (item, type) => {
    setSelectedPersonsState((prev) => {
      const key = type === "TITLE_REPS" ? "titleRepsData" : "affiliatesData";
      const isChecked = prev[key]?.some((selectedItem) => selectedItem._id === item._id);
      const updatedData = isChecked ? prev[key]?.filter((existingItem) => existingItem._id !== item._id) : [...prev[key], item];
      return { ...prev, [key]: updatedData };
    });
  };

  return (
    <>
      {titleRepsData.length > 0 && <GroupList data={titleRepsData} groupKey="titleReps" groupName="Title Company Reps" selectedData={selectedPersonsState.titleRepsData} onGroupSelect={handleGroupCheckboxSelcted} onIndividualSelect={(item) => handleIndividualChanges(item, "TITLE_REPS")} />}

      {affiliatesData.length > 0 && <GroupList data={affiliatesData} groupKey="affiliates" groupName="Other Affiliates" selectedData={selectedPersonsState.affiliatesData} onGroupSelect={handleGroupCheckboxSelcted} onIndividualSelect={(item) => handleIndividualChanges(item, "AFFILIATES")} />}
    </>
  );
};

export default TtileRepsAndAffiliatesList;
