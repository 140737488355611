import {
    Badge,
    Button,
    Checkbox,
    Col,
    Input,
    Modal,
    Popconfirm,
    Row,
    Space,
    Spin,
    Table,
    Tag,
    Typography,
  } from "antd";
  import React, { useEffect, useState, version } from "react";
  import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    EditOutlined,
    SearchOutlined,
  } from "@ant-design/icons";
  import moment from "moment";
  import { fjggDataApis } from "./state/actions";
  import { useDispatch, useSelector } from "react-redux";
  import { Loading } from "../Common/components/Loading";
  import { formatAddress } from '../Common/utils/ExtraFunctions';
import { generateFullNameFromObj } from "../Common/utils/universalFuntions";

  const { Text } = Typography;

  const FjggAccess = () => {
    const dispatch = useDispatch();
    const {FJGGAccessData, FJGGAccessDataError, FJGGAccessDataLoading, fJGGAccessDataAndVersionCheckError, fJGGCheckData, fJGGAccessDataAndVersionCheckLoading} = useSelector((state) => state.fjggReducer);
    const [searchedColumn, setsearchedColumn] = useState("");

    const handleSearch = (data) => {
      dispatch(fjggDataApis.getFJGGAccess(data));
      setsearchedColumn("");
    };

    const handleReset = () => {
      dispatch(fjggDataApis.getFJGGAccess());
      setsearchedColumn("");
    };

    // Modal States
    const [modalState, setModalState] = useState({
      isVisible: false,
      selectedVersions: [],
      selectedBrokerage: null,
    });

    const updateSelectedVersions = (selectedVersions) => {
      setModalState((prevState) => ({
        ...prevState,
        selectedVersions: selectedVersions,
      }));
    };
    
    const openModal = (brokerage) => {
      setModalState((prevState) => ({
        ...prevState,
        isVisible: true,
        selectedBrokerage: brokerage,
      }));
    };

    const closeModal = () => {
      setModalState((prevState) => ({
        ...prevState,
        isVisible: false,
        selectedBrokerage: null,
        selectedVersions: [],
      }));
    };

    const handleGrantAccess = () => {
      if (modalState.selectedBrokerage) {
        dispatch(
          fjggDataApis.grantFJGGAccess({
            brokerageOfficeId: modalState.selectedBrokerage._id,
            // status: modalState.selectedBrokerage.isApproved,
            selectedVersions: modalState.selectedVersions,
          })
        );
      }
      closeModal();
    };

    let getColumnSearchProps = (dataIndex, x) => ({
      filterDropdown: () => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search by Request Id...`}
            value={searchedColumn}
            onChange={(e) => {
              setsearchedColumn(e.target.value ? e.target.value : []);
            }}
            onPressEnter={() => {
              handleSearch(searchedColumn);
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => {
                handleSearch(searchedColumn);
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                handleReset();
              }}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    });

    const columns = [
      {
        title: "Requested By",
        dataIndex: "fullName",
        key: "fullName",
        ellipsis: true,
        align: "center",
        width: "25%",
        render: (fullName, record) => (
          <span>
             <div>{generateFullNameFromObj(record)}</div>
            {record.personEmail && (
              <Text ellipsis>
                {record.personEmail}
              </Text>
            )}
          </span>
        ),
      },
      {
        title: "Brokerage Name",
        dataIndex: "name",
        key: "name",
        ellipsis: true,
        align: "center",
        width: "25%",
        ellipsis: true,
        render: (name, record) => (
          <span>
            <div>{name}</div>
            {record.address && (
              <Text ellipsis>
                {formatAddress(record.address)}
              </Text>
            )}
          </span>
        ),
      },
      {
        title: "Request Details",
        dataIndex: "requestDetails",
        key: "requestDetails",
        width: "30%",
        ellipsis: true,
        ...getColumnSearchProps('requestId'),
        render: (name, record) => (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Text style={{ fontSize: '14px'}}>
              {moment(record?.requestedAt).format("MMMM D, YYYY, hh:mm A")}
            </Text>
            <Text style={{ fontSize: '12px', fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%'}} copyable>
              {record?.requestId}
            </Text>
          </div>
        ),
        align: "center",
        defaultSortOrder: "descend",
      },
      {
        title: "Packages",
        dataIndex: "accessDetails",
        key: "accessDetails",
        ellipsis: true,
        align: "center",
        width: "15%",
        ellipsis: true,
        render: (name, record) => (
          <div className="versionColumn"
            style={{
              display: "flex",
              flexWrap: 'wrap',
              gap: "4px",
              justifyContent: "center",
              alignItems: "center",
              maxHeight: "100px",
              overflow: "auto",
              paddingBottom: '10px',
            }}
          >
            <style>
              {`
              .versionColumn::-webkit-scrollbar {
                  width: 3px;
                  height: 3px;
                }
              `}
            </style>
            {record?.accessDetails?.clauses?.versions?.length ? (
              record?.accessDetails?.clauses?.versions?.map((version, index) => (
                <Tag key={index} color="green" style={{ textAlign: "center" }}>
                  {version}
                </Tag>
              ))
            ) : (
              <span>N/A</span>
            )}
          </div>
        ),
      },
      {
        title: "Action",
        width: "20%",
        key: "isApproved",
        render: (brokerage, record) => (
          <span style={{ display: "flex", flexDirection: "column" }}>
            <div>
              <Badge status={brokerage.isApproved ? "success" : "error"} />
              {brokerage.isApproved ? "Has Access" : "Grant Access"}
            </div>
            <div>
              <Popconfirm
                title={
                  brokerage.isApproved
                    ? `Are you sure you want to edit access to ${brokerage.name}'s brokerage account for FJGG Docs & Clauses?`
                    : `Are you sure you want to allow ${brokerage.name} to use FJGG Docs & Clauses in their brokerage account?`
                }
                onConfirm={() => openModal(brokerage)}
                placement="topRight"
                overlayStyle={{ width: '300px' }}
              >
                <Button
                  key={record._id}
                  icon={brokerage.isApproved ? <EditOutlined /> : <CheckCircleOutlined />}
                  size="large"
                  shape="round"
                  style={{ marginLeft: "18px" }}
                />
              </Popconfirm>
            </div>
          </span>
        ),
        align: "center",
      },
    ];

    useEffect(() => {
      dispatch(fjggDataApis.getFJGGAccess());
    }, []);

    useEffect(() => {
      if (modalState?.isVisible) {
        dispatch(fjggDataApis.checkFJGGAccessAndVersionsAvailable({brokerageOfficeId: modalState.selectedBrokerage._id}));
      }
    }, [modalState.isVisible]);

    useEffect(() => {
      if (modalState?.isVisible && fJGGCheckData) {
        const alreadyAccessVersions = fJGGCheckData?.accessDetails?.clauses?.versions?.filter(version =>
          fJGGCheckData?.versionsAvailable?.fjggClausesVersions?.includes(version)
        );
    
        if (alreadyAccessVersions?.length) {
          setModalState((prevState) => ({
            ...prevState,
            selectedVersions: alreadyAccessVersions,
          }));
        } else {
          setModalState((prevState) => ({
            ...prevState,
            selectedVersions: [],
          }));
        }
      }
    }, [modalState?.isVisible, fJGGCheckData]);

    return (
      <>
        {FJGGAccessDataLoading ? (<Loading />) : (
          <Table
            bordered
            dataSource={FJGGAccessData ? FJGGAccessData : []
            }
            columns={columns}
            pagination={false}
            scroll={{ y: "80vh" }}
          />
        )}

        {/* Modal for Versions */}
        <Modal
          title={`Please select the versions you want to edit the access`}
          visible={modalState.isVisible}
          onCancel={closeModal}
          onOk={handleGrantAccess}
          okText="Modify Access"
          cancelText="Cancel"
          width={400}
        >
          {
            fJGGAccessDataAndVersionCheckLoading ? <div style={{display: "flex", justifyContent: "center", alignItems: 'center'}}><Spin size="middle" /></div> :
              fJGGAccessDataAndVersionCheckError ? <Text type="danger">{fJGGAccessDataAndVersionCheckError}</Text> :
                fJGGCheckData?.versionsAvailable?.fjggClausesVersions?.length > 0 ?
                  <Checkbox.Group
                    value={modalState.selectedVersions}
                    onChange={updateSelectedVersions}
                    style={{ fontSize: 16 }}
                  >
                    <Row>
                      {fJGGCheckData?.versionsAvailable?.fjggClausesVersions?.map((version) => (
                        <Col span={24} key={version}>
                          <Checkbox value={version}>
                            <span>Version: {version}</span>
                          </Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group> :
                  <Text type="danger">No FJGG Versions Available</Text>
          }
        </Modal>
      </>
    );
  };

  export default FjggAccess;
