import React from "react";
import { contractStoreHandle } from "../../ContractStore/state/actions";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Modal, Form, Select, Spin, Empty, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { filterEmptyAndUndefinedValues } from "../../Common/utils/universalFuntions";

const { Option } = Select;
const { confirm } = Modal;

const CreateTemplateModal = ({ urlId, contractAuthKey, modalState, setModalState, contractData }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { templateData, templateLoading } = useSelector((state) => state.contractStore);
  const saveModalCancel = () => {
    setModalState((prevState) => ({ ...prevState, createTemplateModal: false, loadTemplates: false }));
    form?.resetFields();
  };

  const onFinish = (values = {}) => {
    if (!modalState?.loadTemplates) {
      dispatch(
        contractStoreHandle.autoSaveContract({
          currentUrlId: urlId,
          contractAuthKey,
          templateInfo: { createTemplate: true, templateName: values?.templateName, templateDescription: values?.templateDescription },
        })
      );
      saveModalCancel();
    } else if (modalState?.loadTemplates) {
      if (!values?.templateId) {
        message.error("Please select a template.");
        return;
      }
      confirm({
        title: `Your existing values in this contract may be overwritten by values from this template.`,
        content: "Are you sure you want to proceed?",
        okText: "Yes",
        cancelText: "No",
        icon: <ExclamationCircleOutlined />,
        onOk() {
          let localData = JSON.parse(localStorage.getItem(urlId) || "{}") || {};
          let selectedTemplateData = templateData?.find((el) => el._id === values.templateId) || {};
          let templateDataTransactionData = selectedTemplateData?.transactionData;
          templateDataTransactionData = filterEmptyAndUndefinedValues(templateDataTransactionData) || {};

          let finalData = {};
          if (Array.isArray(contractData?.priorityKeysOverTemplate) && contractData.priorityKeysOverTemplate.length) {
            contractData.priorityKeysOverTemplate.forEach((key) => {
              if (localData[key]) {
                // priority to the MLS/Property specific data
                finalData[key] = localData[key];
              } else if (templateDataTransactionData[key]) {
                finalData[key] = templateDataTransactionData[key];
              }
            });
          }

          let newData = Object.assign({}, localData, templateDataTransactionData || {}, finalData);

          // Ensuring agent details are not overwritten from templates
          if (localData?.SellerAgents?.length > 0) {
            newData.SellerAgents = localData.SellerAgents;
          }
          if (localData.officeDetails) {
            newData.officeDetails = localData.officeDetails;
          }
          if (localData?.BuyerAgents?.length > 0) {
            newData.BuyerAgents = localData.BuyerAgents;
          }
          
          localStorage.setItem(urlId, JSON.stringify(newData));
          dispatch(contractStoreHandle.autoSaveContract({ currentUrlId: urlId, contractAuthKey, valueObj: newData }));
          saveModalCancel();
          message.success("Template applied successfully!");
        },
        onCancel() {},
      });
    }
  };

  return (
    <Modal title={<div className="commonModalTitle" >Template Details</div>} visible={modalState?.createTemplateModal} onCancel={saveModalCancel} footer={false}>
      <div style={{ height: modalState?.loadTemplates ? "200px" : "260px" }}>
        {templateLoading ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
            <Spin size="large" />
          </div>
        ) : (
          <>
            <Form layout="vertical" form={form} onFinish={onFinish}>
              {!modalState?.loadTemplates && (
                <>
                  <Form.Item
                    name="templateName"
                    label={<div style={{ fontSize: "16px", fontWeight: "500", color: "#8e959a" }}> Title </div>}
                    rules={[
                      {
                        required: true,
                        message: "Please add title.",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Title" allowClear autoFocus />
                  </Form.Item>
                  <Form.Item name="templateDescription" label={<div style={{ fontSize: "16px", fontWeight: "500", color: "#8e959a" }}> Description </div>}>
                    <Input placeholder="Enter Description" allowClear />
                  </Form.Item>
                </>
              )}
              {modalState?.loadTemplates && templateData?.length > 0 && (
                <Form.Item
                  className="lbl"
                  rules={[
                    {
                      required: true,
                      message: "Please select a template.",
                    },
                  ]}
                  name="templateId"
                  label={<div style={{ fontSize: "16px", fontWeight: "500", color: "#8e959a" }}> Select Template </div>}
                >
                  <Select showSearch placeholder="Select a template" optionFilterProp="children" filterOption={(input, option) => option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0} allowClear notFoundContent="">
                    {templateData.map((ele, index) => {
                      return (
                        <Option key={index} value={ele._id}>
                          {ele.templateName}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}
              {modalState?.loadTemplates && !templateData?.length && (
                <>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>Looks like you do not have any templates created for this document type.</span>} />
                </>
              )}
              {(modalState?.loadTemplates && templateData?.length > 0) || !modalState?.loadTemplates ? (
                <Form.Item style={{ textAlign: "center", marginTop: modalState?.loadTemplates ? "85px" : "40px" }}>
                  <Button onClick={saveModalCancel} style={{ marginRight: "40px" }}>
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit">
                    {modalState?.loadTemplates ? "Apply Template" : "Save"}
                  </Button>
                </Form.Item>
              ) : null}
            </Form>
          </>
        )}
      </div>
    </Modal>
  );
};

export default CreateTemplateModal;
