/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Button, Col, Form, Input, message, Modal, Row, Select } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { contractStoreHandle } from "../../ContractStore/state/actions";
import { buildPropertyFullAddress } from "../../Common/utils/universalFuntions";
import { dirSelectOptions } from "../../Common/commondata/commonData";
import styles from "../styles/CobuyerCosellerList.module.css";
const EditPropertyAddressModal = ({ modalState, setModalState, currentUrlId, contractAuthKey, rootDocData }) => {
  const screens = useBreakpoint();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [anyInputChanged, setAnyInputChanged] = useState(false);
  const { editPropertyAddressModal } = modalState || {};

  useEffect(() => {
    if (editPropertyAddressModal) {
      const localData = JSON.parse(localStorage.getItem(currentUrlId)) || {};
      form.setFieldsValue({
        StreetNumber: localData?.StreetNumber || "",
        DirPrefix: localData?.DirPrefix || "",
        StreetName: localData?.StreetName || "",
        StreetSuffix: localData?.StreetSuffix || "",
        DirSuffix: localData?.DirSuffix || "",
        UnitLabel: localData?.UnitLabel || "",
        UnitNumber: localData?.UnitNumber || "",
        City: localData?.City || "",
        State: localData?.State || "",
        ZipCode: localData?.ZipCode || "",
      });
    }
  }, [editPropertyAddressModal]);

  const handleEditAddressModalCancel = () => {
    setModalState((prevState) => ({ ...prevState, editPropertyAddressModal: false }));
    form.resetFields();
  };

  const handleUpdateAddress = () => {
    form
      .validateFields()
      .then((values) => {
        const trimmedValues = Object.fromEntries(Object.entries(values).map(([key, value]) => [key, typeof value === "string" ? value.trim() : value]));
        trimmedValues.FullAddress = buildPropertyFullAddress(trimmedValues);
        const localData = JSON.parse(localStorage.getItem(currentUrlId)) || {};
        const newData = Object.assign({}, localData, trimmedValues) || {};
        localStorage.setItem(currentUrlId, JSON.stringify(newData));
        dispatch(contractStoreHandle.autoSaveContract({ currentUrlId, contractAuthKey, valueObj: trimmedValues }));
        setModalState((prevState) => ({ ...prevState, editPropertyAddressModal: false }));
        message.success("Property Address Updated Successfully!");
      })
      .catch((info) => {});
  };

  return (
    <>
      <Modal
        visible={modalState?.editPropertyAddressModal}
        width="700px"
        title={<div className="commonModalTitle">Edit Property Address</div>}
        onCancel={handleEditAddressModalCancel}
        footer={
          <>
            <Button onClick={handleEditAddressModalCancel}>Cancel</Button>
            <Button type="primary" disabled={!anyInputChanged} onClick={handleUpdateAddress}>
              Update
            </Button>
          </>
        }
      >
        <div style={{ maxHeight: "50vh", padding: "0px 10px", overflow: "auto", marginTop: "-15px" }}>
          <div className={styles.infoText} style={{ width: "100%" }}>
            (Please ensure the address is consistent across all of the documents.)
          </div>

          <Form form={form} style={{ width: "100%" }} layout="vertical" scrollToFirstError onValuesChange={() => setAnyInputChanged(true)} initialValues={{ UnitLabel: "Unit" }}>
            <Row gutter={[2, 2]} justify="space-between" style={{ width: "100%" }}>
              <Col span={screens.xs ? 23 : 11}>
                <Form.Item className="lbl1" name="StreetNumber" label="Street Number" rules={[{ required: true, message: "Please enter the Street Number." }]}>
                  <Input type="number" placeholder="Enter Street Number" allowClear />
                </Form.Item>
              </Col>
              <Col span={screens.xs ? 23 : 11}>
                <Form.Item className="lbl1" name="DirPrefix" label="Direction Prefix">
                  <Select placeholder="Select Direction Prefix" options={dirSelectOptions} allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[2, 2]} justify="space-between" style={{ width: "100%" }}>
              <Col span={screens.xs ? 23 : 11}>
                <Form.Item className="lbl1" name="StreetName" label="Street Name" rules={[{ required: true, message: "Please enter the Street Name." }]}>
                  <Input placeholder="Enter Street Name" allowClear />
                </Form.Item>
              </Col>
              <Col span={screens.xs ? 23 : 11}>
                <Form.Item className="lbl1" name="StreetSuffix" label="Street Suffix">
                  <Input placeholder="Enter Street Suffix" allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[2, 2]} justify="space-between" style={{ width: "100%" }}>
              <Col span={screens.xs ? 23 : 11}>
                <Form.Item className="lbl1" name="DirSuffix" label="Direction Suffix">
                  <Select placeholder="Select Direction Suffix" options={dirSelectOptions} allowClear />
                </Form.Item>
              </Col>

              <Col span={screens.xs ? 23 : 11}>
                <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                  <Form.Item
                    className="lbl1"
                    label="Unit Label"
                    name="UnitLabel"
                    style={{
                      flex: 1,
                    }}
                  >
                    <Input placeholder="Enter Unit, Apt, or Suite" allowClear />
                  </Form.Item>
                  <Form.Item
                    className="lbl1"
                    label="Unit Number"
                    name="UnitNumber"
                    style={{
                      flex: 1,
                    }}
                  >
                    <Input placeholder="Enter Unit Number" allowClear />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row gutter={[2, 2]} justify="space-between" style={{ width: "100%" }}>
              <Col span={screens.xs ? 23 : 11}>
                <Form.Item className="lbl1" name="City" label="City" rules={[{ required: true, message: "Please enter the City." }]}>
                  <Input placeholder="Enter City" allowClear />
                </Form.Item>
              </Col>
              <Col span={screens.xs ? 23 : 11}>
                <Form.Item className="lbl1" name="State" label="State" rules={[{ required: true, message: "Please enter the State." }]}>
                  <Input placeholder="Enter State" allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[2, 2]} justify="space-between" style={{ width: "100%" }}>
              <Col span={screens.xs ? 23 : 11}>
                <Form.Item
                  className="lbl1"
                  name="ZipCode"
                  label="Zip Code"
                  hasFeedback
                  rules={[
                    { required: true, message: "" },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value && value?.toString().trim().length >= 5 && value.toString().trim().length <= 6) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("Please enter a valid Zip Code."));
                      },
                    }),
                  ]}
                >
                  <Input type="number" maxLength={"6"} allowClear placeholder="Enter Zip Code" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default EditPropertyAddressModal;
