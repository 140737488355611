import axios from "axios";
import dotEnv from "dotenv";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;

const contractPageLoadRequest = (id, accessTokan) => {
  const config = {
    method: "GET",
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  };
  return axios(`${apiURL}/contract/${id}`, config);
};

// create offer load data for persist
const getCreateOfferDataRequest = ({urlId,  sourceAxios}) => {
  const config = {
    method: "GET",
    cancelToken: sourceAxios?.token,
    headers: {
      accept: "application/json",
    },
  };
  return axios(`${apiURL}/url/${urlId}`, config);
};

// persist details from draft && // Template details from draft
const getPersistdetailsDataRequest = (id, accessTokan) => {
  const config = {
    method: "GET",
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  };
  return axios(`${apiURL}/offerDocument/${id}`, config);
};

const getClausesDataRequest = (tokan, id) => {
  let accessTokan =
    localStorage.getItem("auth") ||
    JSON.parse(localStorage.getItem("mobileToken"));
  const config = {
    method: "GET",
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  };
  return axios(`${apiURL}/admin/clauseConstants`, config);
};

const getEmailContentsRequest = (payload, accessToken) => {
  const config = {
    method: "GET",
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  };
  return axios(`${apiURL}/needToKnowEmails?s3Bucket=${payload.s3Bucket}&s3Key=${payload.s3Key}`, config);
};

const getAgentDataDetailsRequest = ({ query, accessToken }) => {

  const config = {
    method: "GET",
    params: query,
    headers: {
      accept: "application/json",
      authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${apiURL}/agentDetails`, config);
};

const getClientDocRequest = ({params, source,contractAuthKey}) => {
  return axios.get(`${apiURL}/propertyDocs`, {
    cancelToken: source.token,
    params,
    headers: {
      accept: "application/json",
      authorization: `bearer ${contractAuthKey}`,
    },
  });
};
const getPersonDocsRequest = ({customerIds, source,contractAuthKey}) => {
  if (Array.isArray(customerIds)) {
    customerIds= JSON.stringify(customerIds);
  }
  return axios.get(`${apiURL}/docsContractDocumentaries`, {
    cancelToken: source.token,
    params: {
     includeBrokerageDoc:true,
      ...(customerIds && { customerIds }),
    },
    headers: {
      // "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${contractAuthKey}`,
    },
  });
};
export const contractDataApi = {
  getClientDocRequest,
  contractPageLoadRequest,
  getClausesDataRequest,
  getCreateOfferDataRequest,
  getPersistdetailsDataRequest,
  getEmailContentsRequest,
  getAgentDataDetailsRequest,
  getPersonDocsRequest
};
