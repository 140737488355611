import { Modal, Checkbox, Row, Col, Button, Typography } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import styles from "../styles/NetSheetAddItemModal.module.css";
import { addCustomBlankInputItem, addNewItem } from '../features/helpers/netSheetDynamicComponents';
import { contractStoreHandle } from '../../ContractStore/state/actions';
import { useDispatch } from 'react-redux';
import { netSheetAdditionalItems } from '../../Common/commondata/commonData';

function NetSheetAddItemModal({ setModalState, currentUrlId, contractAuthKey }) {

    const [selectedItems, setSelectedItems] = useState([]);
    const [customFieldCount, setCustomFieldCount] = useState(0);
    const [alreadyAddedItems, setAlreadyAddedItems] = useState([]);
    const dispatch = useDispatch();

    const filteredSelectedItems = useMemo(() => {
        return selectedItems.filter(
            (item) => !alreadyAddedItems.some((addedItem) => addedItem.name === item.name)
        );
    }, [selectedItems, alreadyAddedItems]);

    useEffect(() => {
        const prePopulateSelectedItems = () => {
            const table = document.getElementById('postEquityGroupTable');
            if (table) {
                const rows = table.getElementsByTagName('tr');
                const matchedItems = [];
                
                Array.from(rows).forEach((row) => {
                    const labelElement = row.querySelector('span'); // Extract the label
                    const inputElements = row.querySelectorAll('input'); // Extract the inputs
                    if (labelElement && inputElements.length > 0) {
                        const label = labelElement.textContent.trim();
                        const inputNames = Array.from(inputElements).map((input) => input.name); // Get names of inputs

                        // Match the table data with items array
                        const matchedItem = netSheetAdditionalItems?.find(
                            (item) => item.label === label && inputNames.includes(item.name)
                        );
                        
                        if (matchedItem) {
                            matchedItems.push(matchedItem);
                        }
                    }
                });

                setSelectedItems(matchedItems);
                setAlreadyAddedItems(matchedItems);
            }
        };

        prePopulateSelectedItems();
    }, []);

    const handleCheckboxChange = (item, e) => {
        if (e.target.checked) {
            setSelectedItems((prev) => [...prev, item]);
        } else {
            setSelectedItems((prev) => prev.filter((selectedItem) => selectedItem.id !== item.id)); // Remove by ID
        }
    };
    
    const closeModal = () => {
        setModalState((prevState) => ({ ...prevState, netSheetAddItemModal: false }))
    }

    const addItemsToTheNetSheet = () => {
        let valueObj = {};
        let presetItems = {};
        let blankItems = {};
        // const filteredSelectedItems = selectedItems.filter(item =>
        //     !alreadyAddedItems.some(addedItem => addedItem.name === item.name)
        // );
        presetItems = addNewItem({ selectedItems: filteredSelectedItems });
        blankItems = customFieldCount > 0 && addCustomBlankInputItem({customFieldCount})
        closeModal();
        valueObj = { ...presetItems, ...blankItems };
        if (valueObj && Object.keys(valueObj).length > 0) {
            let localData = JSON.parse(localStorage.getItem(currentUrlId)) ||{};
            localData = {
              ...localData,
              ...valueObj,
            };
            localStorage.setItem(currentUrlId, JSON.stringify(localData));
            dispatch(contractStoreHandle.autoSaveContract({currentUrlId, contractAuthKey, valueObj}));
        }
    }

    const increaseCount = () => {
        setCustomFieldCount((prevCount) => prevCount + 1); // Increase count when + is clicked
    };

    const decreaseCount = () => {
        if (customFieldCount > 0) {
            setCustomFieldCount((prevCount) => prevCount - 1); // Decrease count when - is clicked
        }
    };

    return (
        <div>
            <Modal
                visible={true}
                okText="Add"
                onOk={addItemsToTheNetSheet}
                onCancel={closeModal}
                title={<div style={{ textAlign: "center", color: '#178DFA' }}>SELECT ITEMS TO ADD</div>}
                okButtonProps={{ disabled: (filteredSelectedItems.length === 0 && customFieldCount === 0) }}
            >
                <div>
                    <Row>
                        {netSheetAdditionalItems?.map((item) => (
                            <Col span={24} key={item.id}>
                                <Checkbox
                                    checked={selectedItems.some((selectedItem) => selectedItem.id === item.id)}
                                    onChange={(e) => handleCheckboxChange(item, e)}
                                    style={{ fontSize: 16 }}
                                >
                                    <span className={styles.feesLabel}>{item.label}</span>
                                </Checkbox>
                            </Col>
                        ))}
                    </Row>
                    <Row style={{ marginTop: 10 }} gutter={15}>
                        <Col>
                            <Typography.Text style={{fontSize: 16}}>Add Custom Field/Add blank input</Typography.Text>
                        </Col>
                        <Col>
                            <Button size="small" onClick={decreaseCount}>-</Button>
                            <span style={{ margin: '0 10px' }}>{customFieldCount}</span>
                            <Button size="small" onClick={increaseCount}>+</Button>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>
    );
}

export default NetSheetAddItemModal;
