import { calendarConstants } from "./action-types";

let dummyReminder = {
  "personId": "6bbb4858456ebf8ad6b16caa",
  "title": "Test",
  "startTimestamp": 999081682142,
  "endTimestamp": 999088882142,
  "duration": 2,
  "dashboardType": "REMINDER",
  "taggedPersonIds": [],
  "description": "Test",
  "isDeleted": false,
  "updatedDateMili": 1734618547907,
  "createdDateMili": 1734618547907,
  "_id": "67694cd6bd12d89b7a021bac",
  "createdAt": "2024-12-23T11:43:18.345Z",
  "updatedAt": "2024-12-23T11:43:18.345Z",
  "__v": 0
}

const initialState = {
  calendarData: [],
  calendarDataLoading: null,
  calendarDataError: null,

  calendarDataEncrypt: null,
  calendarDataEncryptLoading: null,
  calendarDataEncryptError: null,

  getRemindersData: [],
  getRemindersError: null,

  getSchedulesData: [],
  getSchedulesError: null,

  shareCalendarLoading: false,
  calenderMonthFilter: []
};

const calendarData = (state = initialState, action) => {
  switch (action.type) {
    case calendarConstants.CALENDAR_DATA_SUCCESS:
      return {
        ...state,
        calendarData: (action?.calendarData?.length > 0 ) ? action.calendarData : [dummyReminder],
        calendarDataLoading: action.calendarDataLoading,
        calendarDataError: null,
      };
    case calendarConstants.CALENDAR_DATA_FAILURE:
      return {
        ...state,
        calendarData: [],
        calendarDataLoading: action.calendarDataLoading,
        calendarDataError: action.calendarDataError,
      };
    // get calendar data encrypt
    case calendarConstants.CALENDAR_GETDATA_SUCCESS:
      return {
        ...state,
        calendarDataEncrypt: action.calendarDataEncrypt,
        calenderMonthFilter: action.calenderMonthFilter,
        calendarDataEncryptLoading: action.calendarDataEncryptLoading,
        calendarDataEncryptError: null,
      };
    case calendarConstants.CALENDAR_GETDATA_FAILURE:
      return {
        ...state,
        calendarDataEncrypt: [],
        calendarDataEncryptLoading: action.calendarDataEncryptLoading,
        calendarDataEncryptError: action.calendarDataEncryptError,
      };
    case calendarConstants.GET_REMINDERS_LIST_SUCCESS:
      return {
        ...state,
        getRemindersData: action.payload,
      };
    case calendarConstants.GET_SCHEDULE_LIST_SUCCESS:
      return {
        ...state,
        getSchedulesData: action.payload,
      };
    case calendarConstants.GET_CALENDAR_LOADING:
      return {
        ...state,
        calendarDataLoading: action.calendarDataLoading
      };
    case calendarConstants.RESET_CALENDAR:
      return {
        ...initialState,
      };
    case calendarConstants.SHARE_CALENDAR_LOADING:
      return {
        ...state,
        shareCalendarLoading: true,
      };
    case calendarConstants.SHARE_CALENDAR_SUCCESS:
      return {
        ...state,
        shareCalendarLoading: false,
      };
    case calendarConstants.SELECT_AGENT:
      state?.calenderMonthFilter?.forEach((ele, idx)=>{
        if(ele._id === action.selectedAgent._id){
          state.calenderMonthFilter[idx].selected = action.selectedAgent.selected
        }
      })
      return {
        ...state
      }
    default:
      return state;
  }
};
export { calendarData };
