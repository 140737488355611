import React from "react";
import RtdWindow from "./RtdWindow";

const RtdDesign = ({ tags, selectedData, client,userAuthKey, URLofferTableJSON,formattedId,}) => {

  return (
    <>
      <div>
        <RtdWindow
          tags={tags}
          selectedData={selectedData}
          client={client}
          userAuthKey={userAuthKey}
          URLofferTableJSON={URLofferTableJSON}
          formattedId={formattedId}
        />
      </div>
    </>
  );
};

export default RtdDesign;
