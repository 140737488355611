export const fjggConstants = {
    GET_ACCESS_DATA_LOADING: "GET_ACCESS_DATA_LOADING",
    GET_ACCESS_DATA_FAILURE: "GET_ACCESS_DATA_FAILURE",
    GET_ACCESS_DATA_SUCCESS: "GET_ACCESS_DATA_SUCCESS",

    GRANT_ACCESS_TO_FJGG_LOADING:"GRANT_ACCESS_TO_FJGG_LOADING",
    GRANT_ACCESS_TO_FJGG_FAILURE:"GRANT_ACCESS_TO_FJGG_FAILURE",
    GRANT_ACCESS_TO_FJGG_SUCCESS:"GRANT_ACCESS_TO_FJGG_SUCCESS",

    CHECK_FJGG_ACCESS_AND_VERSIONS_AVAILABLE_LOADING: 'CHECK_FJGG_ACCESS_AND_VERSIONS_AVAILABLE_LOADING',
    CHECK_FJGG_ACCESS_AND_VERSIONS_AVAILABLE_SUCCESS: 'CHECK_FJGG_ACCESS_AND_VERSIONS_AVAILABLE_SUCCESS',
    CHECK_FJGG_ACCESS_AND_VERSIONS_AVAILABLE_FAILURE: 'CHECK_FJGG_ACCESS_AND_VERSIONS_AVAILABLE_FAILURE',
  };
